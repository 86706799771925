{/* eslint-disable-line react/no-array-index-key */}
import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import Rating from '@mui/material/Rating';
import { fetchReviews, deleteReview } from '../../redux/actions/userActions';
import { CustomCard } from '../../components/cards';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
  },
  cardPane: {
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  cardGrid: {
    padding: theme.spacing(0),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  footer: {
    width: '100%',
  },
}));

const ViewReviews = () => {
  const classes = useStyles();
  const {
    reviews,
    process: { loading },
  } = useSelector((state) => state);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReviews());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteReview = (data) => {
    dispatch(deleteReview(data));
  };

  return (
    <div className="root">
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <Typography gutterBottom className={clsx('top-radius', classes.header)}>
          Reviews
        </Typography>
        <Container className={classes.cardPane} maxWidth="lg">
          <Grid container className={classes.cardGrid} spacing={2}>
            {loading ? (
              <div>Loading...</div>
            ) : (
              reviews &&
              reviews
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((review, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      item="review"
                      items={[
                        {
                          modal: true,
                          name: 'Delete',
                          action: () => handleDeleteReview({ _id: review._id }),
                        },
                      ]}
                      images={review.images}
                      content={[
                        review.title,
                        review.rating ? (
                          <Rating
                            name="read-only"
                            value={review.rating}
                            readOnly
                          />
                        ) : (
                          ''
                        ),
                        review.name,
                        review.comment,
                        review.date
                          ? moment(review.date).format('YYYY MMM, DD. H:mm A')
                          : '',
                      ]}
                    />
                  </Grid>
                ))
            )}
          </Grid>
        </Container>
        <TablePagination
          className={classes.footer}
          labelRowsPerPage="Reviews per page"
          rowsPerPageOptions={[4, 8, 16]}
          component="div"
          count={reviews?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default ViewReviews;
