import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { uploadEmail} from '../../redux/actions/userActions';
import {
  mapToFormInput,
  FormButton,
  LoadingButton,
} from '../../components/form-components';
//import { createInputData, inputHandler } from '../../utility';
import { createInputData } from '../../utility';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

const AddEmails = () => {
  const classes = useStyles();
  const {
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    email: '',
  };

  const [addEmailState, setAddEmailState] = useState(initialState);

  const onchangeHandler = (e) => { 
    const { name, value } = e.target;
    setAddEmailState((prevState) => ({
    ...prevState,
    [name]: value,
  }));
  };

  const resetHandler = () => {
    setAddEmailState(initialState);
    document.getElementById('myForm').reset();
  };

  console.log('addEmailState:', addEmailState);
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const {
      email
    } = addEmailState;

    
    formData.append('email', email);
    console.log('formData:', formData);
    if ( email) {
        try {
            // Dispatch the action to upload email
            dispatch(uploadEmail({ email }));
    
            // Reset the form after successful submission
            setAddEmailState(initialState);
    
            swal({
              title: 'Success',
              text: 'Email submitted successfully!',
              icon: 'success',
            });
          } catch (error) {
            console.error('Error submitting email:', error);
            swal({
              title: 'Error',
              text: 'An error occurred while submitting the email.',
              icon: 'error',
            });
          }
        } else {
          swal({
            title: 'Hey!',
            text: 'Please enter an email address',
            icon: 'warning',
          });
        }

    //     dispatch(uploadEmail(formData));
    //     resetHandler();
    //   } else {
    //     swal({
    //       title: 'Hey!',
    //       text: 'complete all fields',
    //       icon: 'warning',
    //     });
      
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New Email</p>
          {createInputData(
            {
              email: addEmailState.email,
            },
            onchangeHandler
          )?.map(mapToFormInput)}

          {loading && <LoadingButton content="Submitting Email..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEmails;
