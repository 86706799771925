{/* eslint-disable-line react/no-array-index-key */}import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { FormButton } from '../form-components';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
    height: '100vh',
    minHeight: 300,
  },
  fullList: {
    width: 'auto',
  },
  drawerToolbar: {
    padding: theme.spacing(1),
    height: '100px',
  },
  drawerHeader: {
    margin: theme.spacing(1, 2, 0),
    fontWeight: '800',
    color: '#55c2d9',
  },
  drawerBody: {
    height: 'calc(100vh - 170px)',
    overflowY: 'scroll',
  },
  drawerFooter: {
    padding: theme.spacing(1, 3),
    height: '60px',
  },
}));

export default function TemporaryDrawer(props) {
  const {
    TriggerButton,
    position,
    content: { title, buttons, getContent, Content },
  } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <div className={classes.drawerToolbar}>
        <IconButton
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <CloseIcon />
        </IconButton>
        <p className={classes.drawerHeader}>{title}</p>
      </div>
      <Divider />
      <div className={classes.drawerBody}>
        {Content ? <Content /> : getContent ? getContent() : ''}
      </div>
      <Divider />
      <div className={classes.drawerFooter}>
        {buttons?.map((button, index) => {
          return (
            <FormButton
              key={index}
              content={button.name}
              handler={button.action}
            />
          );
        })}
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <TriggerButton handler={toggleDrawer(position, true)} />
        <Drawer
          anchor={position}
          open={state[position]}
          onClose={toggleDrawer(position, false)}
        >
          {list(position)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
