import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { apiUrl } from '../../config/apiUrl';
import { deleteItemImage } from '../../redux/actions/userActions';
import '../../app-assets/styles/css/style.css';

const imageWidth = 310;
function Slides(props) {
  const dispatch = useDispatch();

  const useStyles = makeStyles(() => ({
    img: {
      width: imageWidth,
      height: (3 / 4) * imageWidth,
    },
    deleteIcon: {
      color: '#55c2d9',
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
    },
  }));

  const classes = useStyles();
  return (
    <Box className={clsx('radius', classes.img)}>
      {props.deleteUrl && (
        <DeleteSharpIcon
          className={classes.deleteIcon}
          onClick={() =>
            dispatch(
              deleteItemImage(
                { image: props.image, ...props.additionalData },
                props.deleteUrl
              )
            )
          }
        />
      )}
      <img
        alt="cake"
        src={
          props.image?.includes('https')
            ? props.image
            : `${apiUrl}/${props.image}`
        }
        width="100%"
        height="100%"
      />
    </Box>
  );
}

export default Slides;
