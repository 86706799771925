import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    color: '#55c2d9',
  },
}));

const FormButton = (props) => {
  const classes = useStyles();
  return (
    <Button className={classes.button} onClick={props.handler}>
      {props.content}
    </Button>
  );
};

export { FormButton };
