import { combineReducers } from 'redux';
import profile from './profileReducer';
import process from './processReducer';
import orders from './ordersReducer';
import cakes from './cakesReducer';
import items from './itemsReducer';
import emails from './emailsReducer';
import gifts from './giftsReducer';
import vendors from './vendorsReducer';
import resources from './resourceReducer';
import users from './usersReducer';
import reviews from './reviewsReducer';
import currentOrder from './currentOrderReducer';

const rootReducer = combineReducers({
  profile,
  process,
  orders,
  cakes,
  emails,
  items,
  gifts,
  vendors,
  users,
  reviews,
  currentOrder,
  resources,
});

export default rootReducer;
