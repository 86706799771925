import { decryptStorage } from '../../helpers/localStorage';
import { LOGIN, LOGOUT } from '../actions/actionTypes';

const initialState = {
  ...{
    id: null,
    token: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    accessLevel: 0,
    avatar: null,
    loggedIn: false,
    rememberMe: false,
  },
  ...decryptStorage('user'),
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload?.token,
        id: action.payload?._id,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        accessLevel: action.payload?.accessLevel,
        loggedIn: true,
        rememberMe: action.payload?.rememberMe,
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default profileReducer;
