{/* eslint-disable-line react/no-array-index-key */}
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { ErrorPage } from '../helpers';
import { apiUrl } from '../../config/apiUrl';
import { punctuateAmount } from '../../utility';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    padding: theme.spacing(4, 3, 6),
    display: 'flex',
    flexWrap: 'wrap',
  },
  header: {
    margin: theme.spacing(3),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(0, 3, 2),
    fontWeight: '800',
    color: '#55c2d9',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  boxContent: {
    margin: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  imageBox: {
    minWidth: '300px',
    minHeight: '300px',
    maxWidth: '500px',
    maxHeight: '500px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  item: {
    margin: '0 0 10px 0',
    maxWidth: '300px',
  },
  badge: {
    color: 'white',
    fontSize: '14px',
    width: 'fit-content',
  },
  marginB: {
    marginBottom: '2px',
  },
}));

const ViewOneOrder = ({ location: { state } }) => {
  const classes = useStyles();

  if (!state) {
    return <ErrorPage />;
  }

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        {/*  Section One */}
        <p className={classes.header}>Order Review</p>
        <div className={clsx(classes.section)}>
          <div className={classes.imageBox}>
            <img
              style={{ width: '100%', height: '100%' }}
              alt="cake"
              src={
                state?.productId?.images[0]?.includes('https')
                  ? `${state?.productId?.images[0]}`
                  : `${apiUrl}/${state?.productId?.images[0]}`
              }
            />
          </div>
        </div>
        {/* Section Two */}
        <div className={classes.section}>
          {/*  Section two Box one */}
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Title</p>
              <p className={clsx(classes.item)}>{state.title}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Description
              </p>
              <p className={clsx(classes.item)}>{state.description || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Color</p>
              <p className={clsx(classes.item)}>{state.color || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Size</p>
              <p className={clsx(classes.item)}>{state.size || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Flavour</p>
              <p className={clsx(classes.item)}>{state.flavour || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Frosting</p>
              <p className={clsx(classes.item)}>{state.frosting || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Inscription
              </p>
              <p className={clsx(classes.item)}>
                {state.custom || state.inscription || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Assigned Vendor
              </p>
              <p className={clsx(classes.item)}>
                {state.assignedVendor?.businessName ||
                  `${state.assignedVendor?.firstName || ''} ${
                    state.assignedVendor?.lastName || ''
                  }`}
              </p>
            </div>
          </div>
          {/*  Section two Box two */}
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Order Type</p>
              <p className={clsx(classes.item)}>{state.orderType || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Order Reference
              </p>
              <p className={clsx(classes.item)}>{state.orderRef || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Cost Price</p>
              <p className={clsx(classes.item)}>
                {punctuateAmount(state.costPrice || state.cakeCost) || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Logistics Cost
              </p>
              <p className={clsx(classes.item)}>
                {punctuateAmount(state.logisticsCost) || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Price</p>
              <p className={clsx(classes.item)}>
                {punctuateAmount(state.sellingPrice || state.totalCost)}
              </p>
            </div>
          </div>
          {/*  Section two Box three */}
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Payment Method
              </p>
              <p className={clsx(classes.item)}>
                {state.payment?.method || state.paymentMethod || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Payment Status
              </p>
              <p className={clsx(classes.item)}>
                {state.payment?.status || state.paymentStatus || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Status</p>
              {state.status.toLowerCase() === 'pending' && (
                <span
                  className={clsx(
                    'badge badge-warning',
                    classes.item,
                    classes.badge
                  )}
                >
                  Pending
                </span>
              )}
              {state.status.toLowerCase() === 'cancelled' && (
                <span
                  className={clsx(
                    'badge badge-danger',
                    classes.item,
                    classes.badge
                  )}
                >
                  Cancelled
                </span>
              )}
              {state.status.toLowerCase() === 'completed' && (
                <span
                  className={clsx(
                    'badge badge-success',
                    classes.item,
                    classes.badge
                  )}
                >
                  Completed
                </span>
              )}
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Delivery Date/Time
              </p>
              <p className={clsx(classes.item)}>
                {state.deliveryDateTime || state.date}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Created</p>
              <p className={clsx(classes.item)}>
                {moment(state.createdAt).format('YYYY MMM, DD. H:mm A')}
              </p>
            </div>
          </div>
        </div>


        {state.additionalItems && (      
          <div>      
        <p className={classes.subheader}>Additional Items</p>
        <Divider />
        <div className={classes.section}>
          {/*  Section three Box one */}
          {state.additionalItems?.map((additionalItem, index) => {
                return (
          <div key={index} className={classes.box}>
            <div className={classes.imageBox}>
            <img
              style={{ width: '25%', height: '25%' }}
              alt="item"
              src={
                state?.productId?.additionalItem?.productImageUrl?.includes('https')
                ? `${state?.productId?.additionalItem?.productImageUrl}`
                : `${apiUrl}/${state?.productId?.additionalItem?.productImageUrl}`
              }/>
          </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Product Name
              </p>
              <p className={clsx(classes.item)}>{additionalItem.productName || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Quantity
              </p>
              <p className={clsx(classes.item)}>{additionalItem.productQuantity || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Unit Price
              </p>
              <p className={clsx(classes.item)}>{punctuateAmount(additionalItem.unitPrice || 'N/A')}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Total Price
              </p>
              <p className={clsx(classes.item)}>
                {punctuateAmount(additionalItem.totalPrice || 'N/A')}
              </p>
            </div>
          </div>
          );
                })}
        </div>
        </div>)}
        <p className={classes.subheader}>Sender & Recipient</p>
        <Divider />
        {/*  Section Three */}
        <div className={classes.section}>
          {/*  Section three Box one */}
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Sender Name
              </p>
              <p className={clsx(classes.item)}>{state.name || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Sender Phone
              </p>
              <p className={clsx(classes.item)}>{state.phone || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Sender Email
              </p>
              <p className={clsx(classes.item)}>{state.email || 'N/A'}</p>
            </div>
          </div>
          {/*  Section three Box two */}
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Recipient Name
              </p>
              <p className={clsx(classes.item)}>
                {state.recipient?.name || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Recipient Phone
              </p>
              <p className={clsx(classes.item)}>
                {state.recipient?.phone || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Recipient Email
              </p>
              <p className={clsx(classes.item)}>
                {state.recipient?.email || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Recipient Address
              </p>
              <p className={clsx(classes.item)}>
                {state.recipient?.address || state.address || 'N/A'}
              </p>
            </div>
          </div>
        </div>
        {state.payment?.shared && (
          <div>
            <p className={classes.subheader}>Share Bill Contributors</p>
            <Divider />
            <div className={classes.section}>
              {state.participants?.map((participant, index) => {
                return (
                  <div key={index} className={classes.box}>
                    <div className={classes.boxContent}>
                      <p className={clsx(classes.marginB, classes.title)}>
                        Name
                      </p>
                      <p className={clsx(classes.item)}>
                        {participant.name || 'N/A'}
                      </p>
                    </div>
                    <div className={classes.boxContent}>
                      <p className={clsx(classes.marginB, classes.title)}>
                        Phone
                      </p>
                      <p className={clsx(classes.item)}>
                        {participant.phone || 'N/A'}
                      </p>
                    </div>
                    <div className={classes.boxContent}>
                      <p className={clsx(classes.marginB, classes.title)}>
                        Email
                      </p>
                      <p className={clsx(classes.item)}>
                        {participant.email || 'N/A'}
                      </p>
                    </div>
                    <div className={classes.boxContent}>
                      <p className={clsx(classes.marginB, classes.title)}>
                        Payment Status
                      </p>
                      <p className={clsx(classes.item)}>
                        {participant.status === 'success'
                          ? 'paid'
                          : participant.status}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewOneOrder;
