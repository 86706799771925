{/* eslint-disable-line react/no-array-index-key */}
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { deleteAlert } from '../custom-alerts';
import Slider from '../slides/slider';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  cardPane: {
    padding: theme.spacing(4),
    backgroundColor: '#f0f0f0',
  },
  cardGrid: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
  },
  cardGridHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    '&:hover': {
      boxShadow:
        '1px 1px 2px rgba(128, 128, 128, .5), -1px -1px 2px rgba(128, 128, 128, .5)',
    },
  },
  cardMedia: {
    paddingTop: '75%', // 4:3
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActionButton: {
    backgroundColor: '#55c2d9',
    color: 'white',
    '&.MuiButton-contained:hover': {
      backgroundColor: '#2a8ea3',
    },
  },
  contentText: {
    color: '#8a8a8a',
    maxHeight: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const CustomCard = (props) => {
  const classes = useStyles();
  const {
    profile: { accessLevel },
  } = useSelector((state) => state);
  return (
    <Card className={classes.card}>
      <Slider
        images={props.images}
        deleteUrl={props.deleteUrl}
        additionalData={props.additionalData}
      />
      <CardContent className={classes.cardContent}>
        {props.content?.map((value, index) => {
          return (
            <Typography
              key={index}
              gutterBottom
              className={classes.contentText}
            >
              {value}
            </Typography>
          );
        })}
      </CardContent>
      {(accessLevel > 1 ) && (
      <CardActions>
        {props.items?.map((item, index) => {
          if (!item.modal) {
            return (
              <Button
                key={index}
                component={Link}
                to={item.address}
                style={{ textDecoration: 'none' }}
              >
                {item.name}
              </Button>
            );
          }
          if (item.name?.toLowerCase() === 'delete') {
            return (
              <Button
                key={index}
                variant="contained"
                size="small"
                className={classes.cardActionButton}
                onClick={() => {
                  deleteAlert(props.item, () => {
                    item.action();
                  });
                }}
              >
                {item.name}
              </Button>
            );
          }
          return (
            <Button
              key={index}
              variant="contained"
              size="small"
              className={classes.cardActionButton}
              onClick={item.action}
            >
              {item.name}
            </Button>
          );
        })}
      </CardActions>)}
    </Card>
  );
};

export default CustomCard;
