import { FETCH_REVIEWS } from '../actions/actionTypes';

const initialState = null;

const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REVIEWS:
      return action.payload;
    default:
      return state;
  }
};

export default reviewsReducer;
