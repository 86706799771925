import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MuiModal } from '../../components/modals';
import {
  deleteEmail,
  updateEmail,
  fetchEmails,
} from '../../redux/actions/userActions';
import { mapToFormInput } from '../../components/form-components';
import { inputHandler, updateItemState, createInputData } from '../../utility';
import { EmailTable } from '../../components/table';

const ViewEmails = () => {
  const {
    profile: { id },
    emails,
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    email: '',
    state: '',
  };

  const [EmailState, setEmailState] = useState(initialState);
  const [updateEmailModal, setUpdateEmailModal] = useState(false);

  useEffect(() => {
    dispatch(fetchEmails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteEmail = (data) => {
    dispatch(deleteEmail(data));
  };

  const onchangeHandler = (e) => {
    inputHandler(e, setEmailState);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const data = { ...EmailState, ...{ updatedBy: id } };
    dispatch(updateEmail(data));
  };

  const toggleUpdateEmailModal = () => {
    setUpdateEmailModal(!updateEmailModal);
  };

  const updateEmailState = (data) => {
    updateItemState(
      data,
      setEmailState,
      toggleUpdateEmailModal,
      initialState
    );
  };

  const renderUpdateEmail = () => {
    return (
      <div>
        {createInputData(EmailState, onchangeHandler)?.map(mapToFormInput)}
      </div>
    );
  };

  return (
    <div className="animated fadeIn">
      <EmailTable
        loading={loading}
        filterTarget="#filterModal"
        rows={emails || ''}
        action={{
          edit: updateEmailState,
          delete: handleDeleteEmail,
        }}
      />

      <MuiModal
        open={updateEmailModal}
        handleClose={toggleUpdateEmailModal}
        title="Update Email"
        content={renderUpdateEmail}
        handler={submitHandler}
      />
    </div>
  );
};

export default ViewEmails;
