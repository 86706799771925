import { FETCH_GIFTS } from '../actions/actionTypes';

const initialState = null;

const giftsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GIFTS:
      return action.payload;
    default:
      return state;
  }
};

export default giftsReducer;
