import jwt from 'jsonwebtoken';

const key =
  process.env.REACT_APP_JWT_SECRET ||
  'jbjfbuewbfuwegfugfz347z843gtugfudbcjbxjcbus';

export const encryptStorage = (name, value, rememberMe) => {
  const token = jwt.sign(value, key, { expiresIn: '24h' });
  localStorage.removeItem(name);
  sessionStorage.removeItem(name);
  if (rememberMe) localStorage.setItem(name, token);
  if (!rememberMe) sessionStorage.setItem(name, token);
};

export const decryptStorage = (name) => {
  const userToken = sessionStorage.getItem(name) || localStorage.getItem(name);

  return jwt.verify(userToken, key, (err, decoded) => {
    if (err) {
      localStorage.removeItem(name);
      sessionStorage.removeItem(name);
      return { token: null, loggedIn: null };
    }
    const {
      id,
      token,
      firstName,
      lastName,
      phone,
      email,
      accessLevel,
      avatar,
      loggedIn,
    } = decoded;
    return {
      id,
      token,
      firstName,
      lastName,
      phone,
      email,
      accessLevel,
      avatar,
      loggedIn,
    };
  });
};
