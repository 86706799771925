import { FETCH_EMAILS } from '../actions/actionTypes';

const initialState = null;

const emailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAILS:
      return action.payload;
    default:
      return state;
  }
};

export default emailsReducer;
