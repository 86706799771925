import { FETCH_VENDORS } from '../actions/actionTypes';

const initialState = null;

const vendorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDORS:
      return action.payload;
    default:
      return state;
  }
};

export default vendorsReducer;
