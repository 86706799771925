export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FAILURE = 'FAILURE';
export const SUCCESS = 'SUCCESS';
export const FETCH_CAKES = 'FETCH_CAKES';
export const FETCH_EMAILS = 'FETCH_EMAILS';
export const FETCH_GIFTS = 'FETCH_GIFTS';
export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_VENDORS = 'FETCH_VENDORS';
export const FETCH_RESOURCES = 'FETCH_RESOURCES';
export const FETCH_USERS = 'FETCH_USERS';
export const DELETE_CAKE = 'DELETE_CAKE';
export const LOADING = 'LOADING';
export const READY = 'READY';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const VIEW_ORDER = 'VIEW_ORDER';
