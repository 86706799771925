{/* eslint-disable-line react/no-array-index-key */}
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { deleteAlert } from '../custom-alerts';

const disabledColor = 'lightgray';

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        Actions
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.items?.map((item, index) => {
          const authorized =
            typeof item.permission === 'boolean' ? item.permission : true;

          if (!item.modal) {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleClose();
                }}
                component={Link}
                to={authorized && item.address}
                style={{
                  textDecoration: 'none',
                  color: !authorized && disabledColor,
                }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (item.name?.toLowerCase() === 'delete') {
            return (
              <MenuItem
                key={index}
                style={{
                  color: !authorized && disabledColor,
                }}
                onClick={() => {
                  authorized &&
                    deleteAlert(props.item, () => {
                      item.action();
                    });
                  handleClose();
                }}
              >
                {item.name}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={index}
              style={{
                color: !authorized && disabledColor,
              }}
              onClick={() => {
                authorized && item.action();
                handleClose();
              }}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
