import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './dashboard/dashboard';
import { Orders, AddOrders, ViewOneOrder } from './order';
import { AddCakes, ViewCakes } from './cake';
import { AddGifts, ViewGifts } from './gift';
import { AddMarketItems, ViewMarketItems } from './market';
import { AddReviews, ViewReviews } from './review';
import { AddVendors, ViewVendors, ViewOneVendor } from './vendor';
import { AddResource, ViewResources } from './resource';
import { AddUsers, ViewUsers } from './user';
import { AddEmails, ViewEmails } from './email';
import { Login } from './login';
import { ErrorPage } from './helpers';
import { ProtectedRoute } from '../components/helpers';
import { encryptStorage } from '../helpers/localStorage';

const Home = () => {
  const user = useSelector((state) => state.profile);
  const { token, accessLevel } = user;

  useEffect(() => {
    const { loggedIn, rememberMe } = user;
    if (loggedIn) {
      encryptStorage('user', user, rememberMe);
    }
  }, [user]);

  if (token) {
    axios.interceptors.request.use((config) => {
      config.headers.authorization = token;
      return config;
    });
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute exact path="/" component={Dashboard} />
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/orders" component={Orders} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/addOrders" component={AddOrders} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/addCakes" component={AddCakes} />)}
        {( accessLevel > 1 ) && (
          <ProtectedRoute path="/viewCakes" component={ViewCakes} />)}
        {( accessLevel > 1 ) && (
            <ProtectedRoute path="/addGifts" component={AddGifts} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/addMarketItems" component={AddMarketItems} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/viewMarketItems" component={ViewMarketItems} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/viewGifts" component={ViewGifts} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/addEmails" component={AddEmails} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/viewEmails" component={ViewEmails} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/addReviews" component={AddReviews} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/viewReviews" component={ViewReviews} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/addVendor" component={AddVendors} />)}
        {( accessLevel > 1 ) && (
        <ProtectedRoute path="/viewVendors" component={ViewVendors} />)}
        {accessLevel > 2 && (
        <ProtectedRoute path="/addUsers" component={AddUsers} />
        )} 
        <ProtectedRoute path="/viewUsers" component={ViewUsers} />
        <ProtectedRoute path="/order/:id" component={ViewOneOrder} />
        <ProtectedRoute path="/vendor/:id" component={ViewOneVendor} />
        <ProtectedRoute path="/addResource" component={AddResource} />
        <ProtectedRoute path="/ViewResources" component={ViewResources} />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Home;
