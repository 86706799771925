import writeXlsxFile from 'write-excel-file';

export const cakeExcelSchema = [
  // Column #1
  {
    column: 'id',
    type: String,
    value: (cake) => cake.productId,
  },
  // Column #2
  {
    column: 'title',
    type: String,
    value: (cake) => cake.title,
  },
  // Column #3
  {
    column: 'description',
    type: String,
    value: (cake) => cake.description,
  },
  // Column #4
  {
    column: 'link',
    type: String,
    value: (cake) => `https://yefepere.com/${cake.images[0]}`,
  },
  // Column #5
  {
    column: 'condition',
    type: String,
    value: () => 'new',
  },
  // Column #6
  {
    column: 'price',
    type: Number,
    format: '#,##0.00',
    value: (cake) => Number(cake.price.replace(/[^0-9]/g, '')),
  },
  // Column #7
  {
    column: 'availability',
    type: String,
    value: () => 'available',
  },
  // Column #8
  {
    column: 'image link',
    type: String,
    value: (cake) => `https://yefepere.com/${cake.images[0]}`,
  },
  // Column #9
  {
    column: 'gtin',
    type: String,
    value: (cake) => cake.title,
  },
  // Column #10
  {
    column: 'mpn',
    type: String,
    value: (cake) => cake.title,
  },
  // Column #11
  {
    column: 'brand',
    type: String,
    value: (cake) => cake.title,
  },
  // Column #12
  {
    column: 'google product category',
    type: String,
    value: (cake) => cake.category,
  },
];

export const createExcel = async (objects, schema, name) => {
  try {
    await writeXlsxFile(objects, {
      schema,
      fileName: `${name}.xlsx`,
    });
  } catch (err) {
    console.log(err);
  }
};
