import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    width: '100%',
    margin: theme.spacing(3, 0),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  header: {
    color: '#55c2d9',
  },
  subheader: {
    marginLeft: theme.spacing(1),
    color: '#c7c7c7',
  },
}));

const EnhancedTableToolbar = ({ heading }) => {
  const classes = useStyles();
  

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.header}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {heading}
      </Typography>
    </Toolbar>
  );
};

export default function BasicTable({ summary, heading }) {
  const classes = useStyles();
  const {
    profile: { accessLevel },
  } = useSelector((state) => state);

  return (
    <div>
    {accessLevel > 2 && (
    <TableContainer component={Paper} className={classes.paper}>
      <EnhancedTableToolbar heading={heading} />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Cost Price Total</TableCell>
            <TableCell align="right">Logistics Cost Total</TableCell>
            <TableCell align="right">Selling Price Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" align="right">
              {summary?.costPriceTotal}
            </TableCell>
            <TableCell align="right">{summary?.logisticsCostTotal}</TableCell>
            <TableCell align="right">{summary?.sellingPriceTotal}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    )}
    </div>
    );
  }
