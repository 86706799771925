import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { ErrorPage } from '../helpers';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    padding: theme.spacing(4, 3, 6),
    display: 'flex',
    flexWrap: 'wrap',
  },
  header: {
    margin: theme.spacing(3),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(0, 3, 2),
    fontWeight: '800',
    color: '#55c2d9',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  boxContent: {
    margin: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  imageBox: {
    minWidth: '300px',
    minHeight: '300px',
    maxWidth: '500px',
    maxHeight: '500px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  item: {
    margin: '0 0 10px 0',
    maxWidth: '300px',
  },
  badge: {
    color: 'white',
    fontSize: '14px',
    width: 'fit-content',
  },
  marginB: {
    marginBottom: '2px',
  },
}));

const ViewOneVendor = ({ location: { state } }) => {
  const classes = useStyles();

  if (!state) {
    return <ErrorPage />;
  }

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <p className={classes.header}>Vendor Review</p>

        <div className={classes.section}>
          {/*  Section One Box one */}
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>First Name</p>
              <p className={clsx(classes.item)}>{state.firstName}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Last Name</p>
              <p className={clsx(classes.item)}>{state.lastName}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Email</p>
              <p className={clsx(classes.item)}>{state.email || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Phone</p>
              <p className={clsx(classes.item)}>{state.phone || 'N/A'}</p>
            </div>
          </div>
        </div>

        <p className={classes.subheader}>Business Details</p>
        <Divider />
        {/*  Section Two */}
        <div className={classes.section}>
          {/*  Section two Box one */}
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Business Name
              </p>
              <p className={clsx(classes.item)}>
                {state.businessName || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Address</p>
              <p className={clsx(classes.item)}>{state.address || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>City</p>
              <p className={clsx(classes.item)}>{state.city || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>State</p>
              <p className={clsx(classes.item)}>{state.state || 'N/A'}</p>
            </div>
          </div>
        </div>

        <p className={classes.subheader}>Account Details</p>
        <Divider />
        {/*  Section Three */}
        <div className={classes.section}>
          {/*  Section three Box one */}
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>Bank</p>
              <p className={clsx(classes.item)}>{state.bank || 'N/A'}</p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Account Number
              </p>
              <p className={clsx(classes.item)}>
                {state.accountNumber || 'N/A'}
              </p>
            </div>
            <div className={classes.boxContent}>
              <p className={clsx(classes.marginB, classes.title)}>
                Account Name
              </p>
              <p className={clsx(classes.item)}>{state.accountName || 'N/A'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOneVendor;
