{/* eslint-disable-line react/no-array-index-key */}import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Slides from './slides';

function Slider(props) {
  const { images, deleteUrl } = props;

  return (
    <Carousel
      animation="fade"
      interval={10000}
      fullHeightHover={false}
      indicatorIconButtonProps={{
        style: {
          padding: '2px',
          color: 'rgba(0, 0, 0, .3)',
          marginTop: '-100px',
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: 'rgba(0, 0, 0, .5)',
        },
      }}
    >
      {images.map((image, i) => (
        <Slides
          key={i}
          image={image}
          deleteUrl={deleteUrl}
          additionalData={props.additionalData}
        />
      ))}
    </Carousel>
  );
}

export default Slider;
