import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MainDrawer } from '../page-components';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useSelector((state) => state.profile);

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <div>
            <MainDrawer>
              <Component {...props} />
            </MainDrawer>
            {/* <Sidebar MainPane={Component} contents={props} />
            <div id="right-panel" className="right-panel">
              <Header />
              <div className="content">
                <Component {...props} />
              </div>
              <div className="clearfix"></div>
              <Footer />
            </div> */}
          </div>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
