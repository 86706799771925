import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
} from '@material-ui/core';
import {
  Link,
  Grid,
  Typography,
  Container,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import { InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { login } from '../../redux/actions/userActions';
import { StyledCheckBox } from '../../components/materialUI';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  formLogo: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    padding: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '100%',
    '& label.Mui-focused': {
      color: '#a2a2a8',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#86868a',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c0c0c2',
      },
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#E97D2B',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e87924',
    },
  },
  themeText: {
    color: '#a2a2a8',
  },
  linkText: {
    color: '#E97D2B',
  },
  checkBox: {
    color: '#949499',
    '&.Mui-checked': {
      color: '#55c2d9',
    },
  },
  formControlLabel: {
    '& .MuiFormControlLabel-label': {
      color: '#a2a2a8',
      fontSize: '14px',
    },
  },
}));

const validateEmail = (email) => {
  const isValid = /^[^\s@]+@[^\s@]+$/;
  return isValid.test(email);
};

export default function Login() {
  const classes = useStyles();
  const user = useSelector((state) => state.profile);
  const { loading } = useSelector((state) => state.process);
  const { accessLevel } = user;
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    password: '',
    email: '',
    rememberMe: false,
    showPassword: false,
  });
  const [emailError, setEmailError] = useState({
    status: false,
    message: '',
  });
  const [passwordError, setPasswordError] = useState({
    status: false,
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const finalValue = name === 'rememberMe' ? event.target.checked : value;
    return setValues((prevValue) => {
      return { ...prevValue, [name]: finalValue };
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const { email, password, rememberMe } = values;
    if (email.length && password.length) {
      if (!validateEmail(email)) {
        return setEmailError({
          status: true,
          message: 'Not a valid email!',
        });
      }
      return dispatch(login({ email, password, rememberMe }));
    } else {
      if (!email.length) {
        setEmailError({
          status: true,
          message: 'Email is required!',
        });
      }
      if (!password.length) {
        setPasswordError({
          status: true,
          message: 'Password is required!',
        });
      }
    }
  };

  // if (user.loggedIn) return <Redirect to="/" />;

  if (user.loggedIn) {
    // access level greater than 1 should be restricted
    if (accessLevel > 1) {
      return <Redirect to="/" />;
    } else {
      return <Redirect to="/error-page" />;
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={clsx(classes.paper, 'radius', 'shadow')}>
        <div className={clsx('header-logo', classes.formLogo)}>
          <img
            src="img/yefepere_logo.png"
            alt="logo"
            width="100%"
            height="100%"
          />
        </div>
        <Typography component="h1" variant="h5" className={classes.themeText}>
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            type="email"
            error={emailError.status}
            helperText={emailError.status ? emailError.message : ''}
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handleChange}
          />
          <FormControl
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            required
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              error={passwordError.status}
            >
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              type={values.showPassword ? 'text' : 'password'}
              error={passwordError.status}
              value={values.password}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={85}
            />
            <FormHelperText error={passwordError.status}>
              {passwordError.status ? passwordError.message : ''}
            </FormHelperText>
          </FormControl>
          <FormControlLabel
            control={
              <StyledCheckBox
                name="rememberMe"
                value="remember"
                color="primary"
                className={classes.checkBox}
                onChange={handleChange}
              />
            }
            label="Remember me"
            className={classes.formControlLabel}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitHandler}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" className={classes.linkText}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" className={classes.linkText}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
        {loading && (
          <button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>{' '}
            &nbsp; Please wait...
          </button>
        )}
      </div>
    </Container>
  );
}
