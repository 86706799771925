import React from 'react';
import Measure from 'react-measure';

export default function CustomMeasure({ setter, customClass, children }) {
  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        setter({ dimensions: contentRect.bounds });
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} className={customClass}>
          {children}
        </div>
      )}
    </Measure>
  );
}
