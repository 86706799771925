import { SUCCESS, LOADING, READY } from '../actions/actionTypes';

const initialState = {
  loading: false,
};

const processReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case READY:
      return {
        ...state,
        loading: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default processReducer;
