import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(3),
  },
}));

const FormInput = (props) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.textField}
      variant="outlined"
      margin="normal"
      // required
      // fullWidth
      id={props.id}
      label={props.labelContent}
      name={props.name}
      disabled={props.disabled}
      onChange={props.handler}
      value={props.value}
    />
  );
};

const mapToFormInput = (item, index) => {
  return (
    <FormInput
      key={index}
      htmlFor={item.htmlFor}
      labelContent={item.labelContent}
      id={item.id}
      name={item.name}
      placeHolder={item.placeHolder}
      value={item.value}
      handler={item.handler}
      disabled={item.disabled}
    />
  );
};

export { FormInput, mapToFormInput };
