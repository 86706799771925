import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    margin: theme.spacing(3),
    width: 'fit-content',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid lightgray',
    width: 220,
  },
  inputWrapperError: {
    border: '1px solid red',
  },
  inputBox: {
    width: 'fit-content',
    padding: 0,
    height: 55,
    borderRadius: '4px 0px 0px 4px',
  },
  labelBox: {
    width: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'gray',
    padding: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  iconBtn: {
    color: 'gray',
    height: 55,
    width: 55,
  },
  helperText: {
    padding: theme.spacing(0, 2),
  },
  helperTextError: {
    color: 'red',
  },
}));

export default function FileUpload(props) {
  const { upload } = props;
  const classes = useStyles();

  const getLabelContent = () => {
    const labelContent = [];
    if (Array.isArray(props.value)) {
    props.value.forEach((image) => {
      labelContent.push(image.name);
    });
    return labelContent.toString();
  }};

  return (
    <div className={classes.root}>
      <div
        className={clsx(
          classes.inputWrapper,
          'radius',
          upload.error && classes.inputWrapperError
        )}
      >
        <div className={classes.inputBox}>
          <input
            accept="image/*"
            className={classes.input}
            id="icon-button-file"
            name={props.name}
            onChange={props.handler}
            multiple
            type="file"
          />
          <label htmlFor="icon-button-file">
            <Button
              className={classes.iconBtn}
              aria-label="upload picture"
              component="span"
            >
              <PhotoCamera />
            </Button>
          </label>
        </div>
        <div className={classes.labelBox}>
          {getLabelContent() ? getLabelContent() : 'Choose file'}
        </div>
      </div>
      <FormHelperText
        className={clsx(
          classes.helperText,
          upload.error && classes.helperTextError
        )}
      >
        {upload.message}
      </FormHelperText>
    </div>
  );
}
