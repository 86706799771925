import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SideBarContent from './sideBarContent';
import Header from './header';
import Footer from './footer';
import { CustomMeasure } from '../helpers';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
    minHeight: '100vh',
    overflowY: 'hidden',
  },
  body: {
    padding: theme.spacing(1),
    overflowY: 'scroll',
    maxWidth: `calc(100vw - ${theme.spacing(7) + 1}px)`,
    [theme.breakpoints.up('sm')]: {
      maxWidth: `calc(100vw - ${theme.spacing(9) + 1}px)`,
      padding: theme.spacing(3),
    },
  },
  footerWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
}));

export default function MainDrawer({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const initialDims = {
    dimensions: {
      width: -1,
      height: -1,
    },
  };
  const [footerDims, setFooterDims] = React.useState(initialDims);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        drawerOpen={open}
        menuButton={{
          clickHandler: handleDrawerOpen,
        }}
      />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <SideBarContent />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div
          className={classes.body}
          style={{
            height: `calc(100vh - ${footerDims.dimensions.height * 2}px)`,
          }}
        >
          {children}
        </div>
        <CustomMeasure
          setter={setFooterDims}
          customClass={classes.footerWrapper}
        >
          <Footer />
        </CustomMeasure>
      </main>
    </div>
  );
}
