{/* eslint-disable-line react/no-array-index-key */}
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FormSelect = (props) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">
        {props.labelContent}
      </InputLabel>
      <Select
        id={props.id}
        name={props.name}
        value={props.value}
        disabled={props.disabled || false}
        onChange={props.handler}
        label={props.labelContent}
        labelId="demo-simple-select-outlined-label"
      >
        {props.options &&
          props.options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                value={option.value}
                disabled={!option.enabled}
              >
                {option.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

const mapToFormSelect = (item, index) => {
  return (
    <FormSelect
      key={index}
      labelContent={item.labelContent}
      id={item.id}
      name={item.name}
      value={item.value}
      handler={item.handler}
      options={item.options}
      disabled={item.disabled}
      enableFirst={item.enableFirst || false}
    />
  );
};

export { FormSelect, mapToFormSelect };
