{/* eslint-disable-line react/no-array-index-key */}
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CustomCard } from '../../components/cards';
import { MuiModal } from '../../components/modals';
import {
  inputHandler,
  updateItemState,
  createInputData,
  fileSizeHandler,
} from '../../utility';
import {
  fetchCakes,
  updateCake,
  deleteCake,
} from '../../redux/actions/userActions';
import {
  mapToFormInput,
  mapToFormSelect,
  FileUpload,
} from '../../components/form-components';
import { cakeExcelSchema, createExcel } from '../../config/excelSchema';
import '../../app-assets/styles/css/style.css';
import { getCakeImage } from '../../redux/actions/userActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
  },
  cardPane: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  cardGrid: {
    padding: theme.spacing(0),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  headerText: {
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  headerButton: {
    border: 'none',
    backgroundColor: '#fff',
    color: 'gray',
    '&:hover': {
      color: '#55c2d9',
    },
  },
  headerButtonIcon: {
    width: '20px !important',
    height: '20px !important',
  },
  footer: {
    width: '100%',
  },
}));

const ViewCakes = () => {
  const classes = useStyles();
  const {
    profile: { id },
    cakes,
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const initialState = {
    title: '',
    description: '',
    price: '',
    category: '',
    size: '',
    height: '',
    width: '',
    frosting: '',
    visible: '',
    flavours: '',
    details: '',
    metaTag: '',
    deliveryOptions: '',
    paymentOptions: '',
    logistics: '',
    images: [],
    _id: '',
  };

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };

  const [cakeState, setCakeState] = useState(initialState);
  const [updateCakeModal, setUpdateCakeModal] = useState(false);
  const [page, setPage] = useState(0);
  const [upload, setUpload] = useState(uploadState);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(fetchCakes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cakes) {
      // cakes.forEach((cake) => {
      //   cake.images.forEach((image) => {
      //     if (!image.includes('http')) {
      //       console.log('cake', image);
      //     }
      //   });
      // });
      dispatch(getCakeImage('1587160669382IMG-20191207-WA0007.png'));
    }
  }, [cakes, dispatch]);

  const handleDeleteCake = (data) => {
    dispatch(deleteCake(data));
  };

  const onchangeHandler = (e) => {
    inputHandler(e, setCakeState);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // const data = { ...cakeState, ...{ updatedBy: id } };
    const formData = new FormData();

    const {
      title,
      description,
      price,
      category,
      size,
      height,
      width,
      flavours,
      visible,
      frosting,
      details,
      metaTag,
      deliveryOptions,
      paymentOptions,
      logistics,
      images,
    } = cakeState;

    images.length &&
      images.forEach((image) => {
        formData.append('images', image);
      });
    title && formData.append('title', title);
    description && formData.append('description', description);
    price && formData.append('price', price);
    category && formData.append('category', category);
    size && formData.append('size', size);
    flavours && formData.append('flavours', flavours);
    frosting && formData.append('frosting', frosting);
    visible && formData.append('visible', visible);
    details && formData.append('details', details);
    logistics && formData.append('logistics', logistics);
    height && formData.append('height', height);
    width && formData.append('width', width);
    deliveryOptions && formData.append('deliveryOptions', deliveryOptions);
    paymentOptions && formData.append('paymentOptions', paymentOptions);
    metaTag && formData.append('metaTag', metaTag);
    formData.append('updatedBy', id);
    dispatch(updateCake(formData, cakeState['_id']));
  };

  const toggleUpdateCakeModal = () => {
    setUpdateCakeModal((prevVal) => !prevVal);
  };

  const updateCakeState = (data) => {
    updateItemState(data, setCakeState, toggleUpdateCakeModal, initialState);
  };

  // Render functions

  const renderUpdateCake = () => {
    return (
      <div>
        {createInputData(
          {
            title: cakeState.title,
            description: cakeState.description,
            height: cakeState.height,
            width: cakeState.width,
            metaTag: cakeState.metaTag,
            details: cakeState.details,
            deliveryOptions: cakeState.deliveryOptions,
            paymentOptions: cakeState.paymentOptions,
            logistics: cakeState.logistics,
            price: cakeState.price,
          },
          onchangeHandler
          )?.map(mapToFormInput)}
        {createInputData(
          {
            category: cakeState.category,
            flavours: cakeState.flavours,
            frosting: cakeState.frosting,
            size: cakeState.size,
            visible: cakeState.visible,
          },
          onchangeHandler,
          {
            category: [
              {
                name: 'Select Category',
                value: '',
                enabled: false,
              },
              {
                name: 'Regular',
                value: 'regular',
                enabled: true,
              },
              {
                name: 'Adults',
                value: 'adults',
                enabled: true,
              },
              {
                name: 'Kids',
                value: 'kids',
                enabled: true,
              },
              {
                name: 'Weddings',
                value: 'weddings',
                enabled: true,
              },
              {
                name: 'Novelty',
                value: 'novelty',
                enabled: true,
              },
            ],
            size: [
              {
                name: 'Select Size',
                value: '',
                enabled: false,
              },
              {
                  name: '8x4',
                  value: '8x4',
                  enabled: true,
                },
                {
                  name: '8x6',
                  value: '8x6',
                  enabled: true,
                },
                {
                  name: '8x8',
                  value: '8x8',
                  enabled: true,
                },
                {
                  name: '10x6',
                  value: '10x6',
                  enabled: true,
                },
                {
                  name: '12x6',
                  value: '12x6',
                  enabled: true,
                },
            ],
            flavours: [
              {
                name: 'Select Flavour',
                value: '',
                enabled: false,
              },
              {
                name: ' vanilla',
                value: 'vanilla',
                enabled: true,
              },
              {
                name: 'strawberry',
                value: 'strawberry',
                enabled: true,
              },
              {
                name: 'chocolate',
                value: 'chocolate',
                enabled: true,
              },
              {
                name: 'red-velvet',
                value: 'red-velvet',
                enabled: true,
              },
              {
                name: 'chocolate & strawberry',
                value: 'chocolate & strawberry',
                enabled: true,
              },
              {
                name: 'chocolate & red-velvet',
                value: 'chocolate & red-velvet',
                enabled: true,
              },
              {
                name: 'strawberry & red-velvet',
                value: 'strawberry & red-velvet',
                enabled: true,
              },
              {
                name: 'vanilla & chocolate',
                value: 'vanilla & chocolate',
                enabled: true,
              },
              {
                name: 'vanilla & strawberry',
                value: 'vanilla & strawberry',
                enabled: true,
              },
              
              {
                name:'vanilla, red-velvet',
                value:'vanilla, red-velvet',
                enabled: true,
              },
              {
                name:'vanilla, red-velvet & chocolate',
                value:'vanilla, red-velvet & chocolate',
                enabled: true,
              },
              {
                name:'vanilla, strawberry & red-velvet',
                value:'vanilla, strawberry & red-velvet',
                enabled: true,
              },
            ],
             frosting: [
                {
                  name: 'Select Frosting',
                  value: '',
                  enabled: false,
                },
                {
                  name: ' whippedcream',
                  value: 'whippedcream',
                  enabled: true,
                },
                {
                  name: 'fondant',
                  value: 'fondant',
                  enabled: true,
                },
                {
                  name: 'butter-icing',
                  value: 'butter-icing',
                  enabled: true,
                },
            ],
            visible: [
              {
                name: 'Select visibility',
                value: '',
                enabled: false,
              },
              {
                name: ' true',
                value: 'true',
                enabled: true,
              },
              {
                name: 'false',
                value: 'false',
                enabled: true,
              },
            ],
          }
        )?.map(mapToFormSelect)}

        <FileUpload
          id="images"
          name="images"
          upload={upload}
          value={cakeState.images}
          handler={(event) =>
            fileSizeHandler(
              event,
              onchangeHandler,
              setUpload,
              uploadState,
              3000000
            )
          }
        />
        {createInputData(
          {
            createdBy: cakeState.createdBy,
            updatedBy: cakeState.updatedBy,
          },
          onchangeHandler
        )?.map(mapToFormInput)}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <div className={classes.header}>
          <Typography
            gutterBottom
            className={clsx('top-radius', classes.headerText)}
          >
            Cakes
          </Typography>
          <button
            className={classes.headerButton}
            onClick={() => {
              if (cakes)
                return createExcel(
                  cakes,
                  cakeExcelSchema,
                  'google-merchant-product'
                );
              return null;
            }}
          >
            Excel
            <GetAppIcon className={classes.headerButtonIcon} />
          </button>
        </div>
        <Container className={classes.cardPane} maxWidth="lg">
          <Grid container className={classes.cardGrid} spacing={2}>
            {loading ? (
              <div>Loading...</div>
            ) : (
              cakes &&
              cakes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((cake, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      item="cake"
                      items={[
                        {
                          modal: true,
                          name: 'Edit',
                          action: () => updateCakeState(cake),
                        },
                        {
                          modal: true,
                          name: 'Delete',
                          action: () => handleDeleteCake({ _id: cake._id }),
                        },
                      ]}
                      images={cake.images}
                      deleteUrl={`api/v1/dashboard/product/${cake['_id']}/image/delete`}
                      content={[
                        cake.title,
                        // cake.description,
                        cake.flavours,
                        cake.frosting,
                        cake.size,
                        cake.price,
                      ]}
                    />
                  </Grid>
                ))
            )}
          </Grid>
        </Container>
        <TablePagination
          className={classes.footer}
          labelRowsPerPage="Cakes per page"
          rowsPerPageOptions={[4, 8, 16]}
          component="div"
          count={cakes?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <MuiModal
        open={updateCakeModal}
        handleClose={toggleUpdateCakeModal}
        title="Update Cake"
        content={renderUpdateCake}
        handler={submitHandler}
      />
    </div>
  );
};

export default ViewCakes;
