import swal from 'sweetalert';

export default function deleteAlert(text, action) {
  return swal({
    title: 'Confirm!',
    text: `Delete this ${text || 'item'}?`,
    icon: 'warning',
    buttons: ['Cancel', 'Delete'],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      action && action();
    }
  });
}
