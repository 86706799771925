import React, { useState, useMemo } from 'react';
import moment from 'moment';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import MuiTableActions from './muiTableActions';
import { DateFilter } from '../form-components';
import { muiTableDateFilter } from '../../utility';

function generateColumns({
  rows,
  action,
  dropdown: { open, toggleDropDown },
  dateFilter,
  setDateFilter,
}) {
  // Helper Functions
  const setFilterList = (col) => {
    const thisColumn = dateFilter.column === col;
    return thisColumn
      ? [
          dateFilter.startDate?.format('DD MMM YYYY hh:mm:ss a Z'),
          dateFilter.endDate?.format('DD MMM YYYY hh:mm:ss a Z'),
        ]
      : [];
  };

  const customFilterRender = (list) => {
    if (list[0] && list[1]) {
      return [`Min Date: ${list[0]}`, `Max Date: ${list[1]}`];
    } else if (list[0]) {
      return `Min Date: ${list[0]}`;
    } else if (list[1]) {
      return `Max Date: ${list[1]}`;
    }
    return [];
  };

  const handleListUpdate = (filterList, filterPos, index) => {
    if (filterPos === 0) {
      filterList[index].splice(filterPos, 1, '');
      setDateFilter((prevVal) => ({
        ...prevVal,
        startDate: null,
      }));
    } else if (filterPos === 1) {
      filterList[index].splice(filterPos, 1);
      setDateFilter((prevVal) => ({
        ...prevVal,
        endDate: null,
      }));
    } else if (filterPos === -1) {
      filterList[index] = [];
      setDateFilter((prevVal) => ({
        ...prevVal,
        startDate: null,
        endDate: null,
      }));
    }

    return filterList;
  };

  return [
    {
      name: '',
      label: 'Date Filter',
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'custom',
        filterList: null,
        customFilterListOptions: {
          render: customFilterRender,
          update: handleListUpdate,
        },
        filterOptions: {
          logic(date, filters) {
            return muiTableDateFilter(date, filters[0], filters[1]);
          },
          display: () => {
            return (
              <div>
                <DateFilter
                  state={dateFilter}
                  setState={setDateFilter}
                  radio={[
                    { label: 'Created Date', value: 'created' },
                    { label: 'Modified Date', value: 'modified' },
                  ]}
                />
              </div>
            );
          },
        },
        sort: false,
        searchable: false,
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = rows && rows[tableMeta.rowIndex];
          return value
            ? value
            : rowData.google?.phone || rowData.facebook?.phone || null;
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = rows && rows[tableMeta.rowIndex];
          return value
            ? value
            : rowData.google?.email || rowData.facebook?.email || null;
        },
      },
    },
    {
      name: 'businessName',
      label: 'Business Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Created',
      options: {
        display: false,
        searchable: false,
        filter: true,
        filterType: 'custom',
        filterList: setFilterList('created'),
        customFilterListOptions: {
          render: customFilterRender,
          update: handleListUpdate,
        },
        filterOptions: {
          logic(date, filters) {
            return muiTableDateFilter(date, filters[0], filters[1]);
          },
          display: () => {
            return <div></div>;
          },
        },
        sort: false,
        customBodyRender: (value) =>
          moment(value).format('DD-MMM-YYYY HH:mm:ss'),
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      options: {
        display: false,
        searchable: false,
        filter: true,
        filterType: 'custom',
        filterList: setFilterList('modified'),
        customFilterListOptions: {
          render: customFilterRender,
          update: handleListUpdate,
        },
        filterOptions: {
          logic(date, filters) {
            return muiTableDateFilter(date, filters[0], filters[1]);
          },
          display: () => {
            return <div></div>;
          },
        },
        sort: false,
        customBodyRender: (value) =>
          moment(value).format('DD-MMM-YYYY HH:mm:ss'),
      },
    },
    {
      name: '_id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        searchable: false,
        download: false,
        customBodyRender: (_, tableMeta) => {
          const row = rows && rows[tableMeta.rowIndex];
          return (
            <MuiTableActions
              item="vendor"
              items={[
                {
                  modal: false,
                  name: 'View',
                  address: {
                    pathname: `/vendor/${row._id}`,
                    state: row,
                  },
                },
                {
                  modal: true,
                  name: 'Edit',
                  action: () => action.edit(row),
                },
                {
                  modal: true,
                  name: 'Delete',
                  action: () => action.delete({ _id: row._id }),
                },
              ]}
              state={open}
              toggle={() => toggleDropDown}
            />
          );
        },
      },
    },
  ];
}

// using the outlined chip from Material UI:
const CustomChip = ({ label, onDelete }) => {
  return (
    <Chip
      variant="outlined"
      color="primary"
      label={label}
      onDelete={onDelete}
    />
  );
};

// Here is the custom filter list component that will display
// the custom filter chips:
const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

export default function OrderTable({ rows, action }) {
  const [open, setOpen] = useState(false);
  const initialDateFilter = {
    startDate: null,
    endDate: null,
    isChecked: false,
    column: 'created',
  };

  const [dateFilter, setDateFilter] = useState(initialDateFilter);

  const toggleDropDown = () => setOpen((prevState) => !prevState);

  const memoizedTable = useMemo(
    () => {
      if (rows) {
        return generateColumns({
          rows,
          action,
          dropdown: { open, toggleDropDown },
          dateFilter,
          setDateFilter,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateFilter, rows]
  );

  const options = {
    filterType: 'dropdown',
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25],
    jumpToPage: true,
    onFilterChange: (type) => {
      if (type === 'reset') {
        return setDateFilter(initialDateFilter);
      }
      return null;
    },
  };

  return (
    rows && (
      <div>
        <MUIDataTable
          title={'Vendors'}
          data={rows}
          columns={memoizedTable}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
      </div>
    )
  );
}
