import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { MuiModal } from '../../components/modals';
import {
  mapToFormInput,
  mapToFormSelect,
  CustomSelect,
  DateInput,
} from '../../components/form-components';
import {
  fetchOrders,
  updateOrder,
  deleteOrder,
  fetchVendors,
} from '../../redux/actions/userActions';
import { inputHandler, updateItemState, createInputData } from '../../utility';
import { OrderTable } from '../../components/table';
import '../../app-assets/styles/css/style.css';

const Orders = () => {
  const {
    profile: { id, accessLevel },
    orders,
    vendors,
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();


  // Initial States
  const initialState = {
    _id: '',
    orders: null,
    payment: '',
    status: '',
    cakeCost: '',
    totalCost: '',
    costPrice: '',
    deliveryDate: '',
    logisticsCost: '',
    sellingPrice: '',
    assignedVendor: '',
  };

  // States
  const [orderState, setOrderState] = useState(initialState);
  const [updateModal, setUpdateModal] = useState(false);
  const [vendorList, setVendorList] = useState('' || []);

  //UseEffect
  useEffect(() => {
    dispatch(fetchOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (orders) dispatch(fetchVendors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    if (vendors) {
      const getVendorList = vendors.map((vendor) => {
        const mapped = {
          name: `${
            vendor.businessName || vendor.firstName + ' ' + vendor.lastName
          } in ${vendor.city && vendor.city} ${vendor.state && vendor.state}`,
          label: `${
            vendor.businessName || vendor.firstName + ' ' + vendor.lastName
          } in ${vendor.city && vendor.city} ${vendor.state && vendor.state}`,
          value: vendor['_id'],
          selected: orderState.assignedVendor?._id === vendor['_id'],
        };
        return mapped;
      });
      setVendorList(getVendorList);
    }
  }, [vendors, orderState]);

  // Handlers
  const handleUpdateOrder = (e) => {
    e.preventDefault();
    if (
      (orderState.sellingPrice || orderState.totalCost) &&
      orderState.logisticsCost && orderState.deliveryDate &&
      (orderState.costPrice || orderState.cakeCost)
    ) {
      const data = {
        _id: orderState._id,
        sellingPrice: orderState.sellingPrice,
        costPrice: orderState.costPrice,
        logisticsCost: orderState.logisticsCost,
        deliveryDate: orderState.deliveryDate,
        status: orderState.status,
        payment: orderState.payment,
        assignedVendor: orderState.assignedVendor,
        updatedBy: id,
      };
      dispatch(updateOrder(data));
    } else {
      swal({
        title: 'Cannot perform operation',
        text: 'Some fields are required',
        icon: 'warning',
      });
    }
  };

  const handleDeleteOrder = (data) => {
    dispatch(deleteOrder(data));
  };

  const onchangeHandler = (e) => {
    inputHandler(e, setOrderState);
  };

  const handleVendorChange = (val) => {
    setOrderState((prevVal) => {
      return {
        ...prevVal,
        assignedVendor: val?.value,
      };
    });
  };

  // Toggle functions
  const toggleUpdateOrderModal = () => {
    setUpdateModal(!updateModal);
  };

  const handleOrderUpdateModal = (data) => {
    updateItemState(data, setOrderState, toggleUpdateOrderModal, initialState);
  };

  // Render functions
  const renderUpdateOrder = () => {
    return (
      <div>
        {createInputData(
          {
            costPrice: orderState.costPrice || orderState.cakeCost,
            logisticsCost: orderState.logisticsCost,
            sellingPrice: orderState.sellingPrice || orderState.totalCost,
          },
          onchangeHandler
        )?.map(mapToFormInput)}

        {createInputData(
          {
            status: orderState.status,
            paymentStatus: orderState.payment,
          },
          onchangeHandler,
          {
            status: [
              {
                name: 'Select Status',
                value: '',
                enabled: false,
              },
              {
                name: 'Pending',
                value: 'pending',
                enabled: true,
              },
              {
                name: 'Completed',
                value: 'completed',
                enabled: true,
              },
              {
                name: 'Cancelled',
                value: 'cancelled',
                enabled: true,
              },
            ],
            paymentStatus: [
              {
                name: 'Confirm Payment Status',
                value: '',
                enabled: false,
              },
              {
                name: 'Pending',
                value: 'pending',
                enabled: true,
              },
              {
                name: 'Success',
                value: 'success',
                enabled: true,
              },
            ],
          }
        )?.map(mapToFormSelect)}

          <DateInput
            labelContent="Delivery Date"
            value={orderState.deliveryDate}
            minimumDate={new Date(
              new Date().setHours(new Date().getHours() + 12)
            )
              .toISOString()
              .slice(0, 10)}
            handler={(date) => {
              setOrderState((prevVal) => {
                return { ...prevVal, deliveryDate: date };
              });
            }}
          />

        <CustomSelect
          options={vendorList}
          name="vendors"
          handler={handleVendorChange}
        />
      </div>
    );
  };

  return (
    <div className="animated fadeIn">
       
      <OrderTable
        loading={loading}
        rows={orders || []}
        adminAccess={accessLevel}
        action={{
          update: handleOrderUpdateModal,
          delete: handleDeleteOrder,
        }}
      />

      <MuiModal
        open={updateModal}
        handleClose={toggleUpdateOrderModal}
        title="Update Order"
        content={renderUpdateOrder}
        handler={handleUpdateOrder}
      />
    </div>
  );
};

export default Orders;
