import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { addUser } from '../../redux/actions/userActions';
import {
  mapToFormInput,
  FormButton,
  LoadingButton,
  mapToFormSelect,
  FormInput,
} from '../../components/form-components';
import { createInputData, inputHandler } from '../../utility';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

const AddUsers = () => {
  const classes = useStyles();
  const {
    profile: { id },
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    accessLevel: '',
  };
  const [addUserState, setAddUserState] = useState(initialState);

  const onchangeHandler = (e) => {
    inputHandler(e, setAddUserState);
  };

  const resetHandler = () => {
    setAddUserState(initialState);
    document.getElementById('myForm').reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const { email } = addUserState;
    if (email) {
      const data = { ...addUserState, ...{ createdBy: id } };
      dispatch(addUser(data));
      resetHandler();
    } else {
      swal({
        title: 'Hey!',
        text: 'complete all fields',
        icon: 'warning',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New User</p>
          {createInputData({firstName:addUserState.firstName, lastName:addUserState.lastName, email:addUserState.email }, onchangeHandler)?.map(mapToFormInput)}
          <FormInput
            htmlFor="password"
            labelContent="password"
            id="password"
            name="password"
            type="password"
            placeHolder="password"
            value={addUserState.password}
            handler={onchangeHandler}
            disabled={false}
          />
          {createInputData(
            {
              accessLevel: addUserState.accessLevel,
            },
            onchangeHandler,
            {
              accessLevel: [
                {
                  name: 'User',
                  value: 1,
                  enabled: true,
                },
                {
                  name: 'Developer',
                  value: 2,
                  enabled: true,
                },
                {
                  name: 'Admin',
                  value: 3,
                  enabled: true,
                },
                {
                  name: 'Super Admin',
                  value: 4,
                  enabled: true,
                },
              ],
             
            }
          )?.map(mapToFormSelect)}
          {loading && <LoadingButton content="Submitting Vendor..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUsers;
