import React from 'react';
import Select from 'react-select';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  select: {
    margin: theme.spacing(3),
    minWidth: 280,
  },
}));

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderColor: isFocused ? '#55c2d9' : 'lightgray',
    boxShadow: isFocused && '0 0 0 1px #55c2d9',
    '&:hover': {
      borderColor: isFocused ? '#55c2d9' : '',
    },
    backgroundColor: 'white',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected && 'gray',
      backgroundColor: isSelected ? '#EBEBEB' : isFocused ? '#f5f7f7' : '',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (!isSelected ? '#EBEBEB' : ''),
      },
    };
  },
};

const CustomSelect = (props) => {
  const classes = useStyles();
  return (
    <Select
      className={clsx('basic-single', classes.select)}
      classNamePrefix="select"
      defaultValue={
        props.options?.filter((option) => option.selected === true)[0]
      }
      isClearable={true}
      isSearchable={true}
      name={props.name}
      options={props.options}
      onChange={props.handler}
      styles={colourStyles}
    />
  );
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    })
  ),
  name: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
};

export default CustomSelect;
