import axios from 'axios';
import swal from 'sweetalert';
import { apiUrl } from '../../config/apiUrl';
import {
  FETCH_ORDERS,
  SUCCESS,
  FETCH_CAKES,
  FETCH_GIFTS,
  FETCH_EMAILS,
  FETCH_ITEMS,
  FETCH_REVIEWS,
  FETCH_VENDORS,
  FETCH_RESOURCES,
  FETCH_USERS,
  LOADING,
  READY,
  LOGIN,
  LOGOUT,
  VIEW_ORDER,
} from './actionTypes';

const success = (res) => {
  const {
    status,
    data: { message },
  } = res;
  swal({
    title: status ? status : 'OK!',
    text: message ? message : 'operation successful',
    icon: 'success',
  });
};

const failure = (err) => {
  if (err.response?.data) {
    const {
      status,
      data: { message },
    } = err.response;
    swal({
      title: status ? status : 'Oops!',
      text: message ? message : 'something went wrong, try again',
      icon: 'error',
    });
  } else {
    swal({
      title: 'Oops!',
      text: 'something went wrong, try again',
      icon: 'error',
    });
  }
};

const addItem = (action, path, data) => {
  const url = `${apiUrl}/${path}`;
  action({
    type: LOADING,
  });
  axios({
    method: 'post',
    url,
    data,
  })
    .then((result) => {
      success(result);
      action({
        type: SUCCESS,
      });
    })
    .catch((err) => {
      failure(err);
      action({
        type: READY,
      });
    });
};

const fetchAllItems = async (action, actionType, path) => {

  action({
    type: LOADING,
  });
  const url = `${apiUrl}/${path}`;
  try{
    const result = await fetch(url);
    const { payload } = await result.json();
      action({
        type: actionType,
        payload,
      });
      action({
        type: SUCCESS,
      });

  }catch(err){
    failure(err);
    action({
      type: READY,
    });
  }
  
};

const updateOneItem = (action, path, fetchItems, data) => {
  const url = `${apiUrl}/${path}`;
  action({
    type: LOADING,
  });
  axios({
    method: 'patch',
    url,
    data,
  })
    .then((result) => {
      success(result);
      action(fetchItems());
    })
    .catch((err) => {
      failure(err);
      action({
        type: READY,
      });
    });
};

const deleteOneItem = (action, path, fetchItems, data) => {
  const url = `${apiUrl}/${path}`;
  action({
    type: LOADING,
  });
  axios({
    method: 'delete',
    url,
    data,
  })
    .then((result) => {
      success(result);
      action(fetchItems());
    })
    .catch((err) => {
      failure(err);
      action({
        type: READY,
      });
    });
};

export const getItemImage = (path) => {
  return (dispatch) => {
    dispatch({
      type: LOADING,
    });
    const url = `${apiUrl}/${path}`;
    axios({
      method: 'get',
      url,
    })
      .then((result) => {
        console.log('item', result);
        dispatch({
          type: SUCCESS,
        });
      })
      .catch((err) => {
        failure(err);
        dispatch({
          type: READY,
        });
      });
  };
};
export const getCakeImage = (path) => {
  return (dispatch) => {
    dispatch({
      type: LOADING,
    });
    const url = `${apiUrl}/${path}`;
    axios({
      method: 'get',
      url,
    })
      .then((result) => {
       console.log('cake', result);
        dispatch({
          type: SUCCESS,
        });
      })
      .catch((err) => {
        failure(err);
        dispatch({
          type: READY,
        });
      });
  };
};

export const startLoading = () => {
  return (dispatch) => {
    dispatch({
      type: LOADING,
    });
  };
};

export const stopLoading = () => {
  return (dispatch) => {
    dispatch({
      type: READY,
    });
  };
};

// Dashboard actions
export const login = (data) => {
  const { email, password, rememberMe } = data;
  return (dispatch) => {
    const url = `${apiUrl}/auth/login/email`;
    dispatch({
      type: LOADING,
    });
    axios({
      method: 'post',
      url,
      data: { email, password },
    })
      .then((result) => {
        const { payload } = result.data;
        payload.rememberMe = rememberMe;

        dispatch({
          type: LOGIN,
          payload,
        });
        dispatch({
          type: SUCCESS,
        });
      })
      .catch((err) => {
        console.log('error:', err);
        failure(err);
        dispatch({
          type: READY,
        });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
};

// Order actions
export const viewOneOrder = (data) => {
  return (dispatch) => {
    dispatch({
      type: VIEW_ORDER,
      payload: data,
    });
  };
};

export const fetchOrders = () => {
  return (dispatch) =>
    fetchAllItems(dispatch, FETCH_ORDERS, 'api/v1/dashboard/orders',);
};

export const createOrder = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/order/create', data);
};

export const updateOrder = (data) => {
  return (dispatch) =>
    updateOneItem(
      dispatch,
      `api/v1/dashboard/order/${data?.['_id']}/update`,
      fetchOrders,
      data
    );
};

export const deleteOrder = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/order/${data?.['_id']}/delete`,
      fetchOrders,
      data
    );
};

// Cake actions
export const fetchCakes = () => {
  return (dispatch) =>
    fetchAllItems(dispatch, FETCH_CAKES, 'api/v1/dashboard/products');
};

export const uploadCake = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/product/add', data);
};

export const updateCake = (data, id) => {
  return (dispatch) =>
    updateOneItem(
      dispatch,
      `api/v1/dashboard/product/${id}/update`,
      fetchCakes,
      data
    );
};

export const deleteCake = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/product/${data?.['_id']}/delete`,
      fetchCakes,
      data
    );
};

export const deleteItemImage = (data, url) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      url,
      url.includes('product')
        ? fetchCakes
        : url.includes('gift')
        ? fetchGifts
        : () => fetchResources(data.owner),
      data
    );
};

// Item actions
export const uploadItem = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/item/add', data);
};

export const fetchItems = () => {
  return (dispatch) =>
    fetchAllItems(dispatch, FETCH_ITEMS, 'api/v1/dashboard/items');
};

export const updateItem = (data, id) => {
  return (dispatch) =>
    updateOneItem(
      dispatch,
      `api/v1/dashboard/item/${id}/update`,
      fetchItems,
      data
    );
};

export const deleteItem = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/item/${data?.['_id']}/delete`,
      fetchItems,
      data
    );
};

// Gift actions
export const fetchGifts = () => {
  return (dispatch) =>
    fetchAllItems(dispatch, FETCH_GIFTS, 'api/v1/dashboard/gifts',);
};

export const uploadGift = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/gift/add', data);
};

export const updateGift = (data, id) => {
  return (dispatch) =>
    updateOneItem(
      dispatch,
      `api/v1/dashboard/gift/${id}/update`,
      fetchGifts,
      data
    );
};

export const deleteGift = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/gift/${data?.['_id']}/delete`,
      fetchGifts,
      data
    );
};

// Email actions
export const fetchEmails = () => {
  return (dispatch) =>
    fetchAllItems(dispatch, FETCH_EMAILS, 'api/v1/dashboard/emails',);
};

export const uploadEmail = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/email/add', data);
};

export const updateEmail = (data, id) => {
  return (dispatch) =>
    updateOneItem(
      dispatch,
      `api/v1/dashboard/email/${id}/update`,
      fetchEmails,
      data
    );
};

export const deleteEmail = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/email/${data?.['_id']}/delete`,
      fetchEmails,
      data
    );
};

// Review actions
export const fetchReviews = () => {
  return (dispatch) =>
    fetchAllItems(dispatch, FETCH_REVIEWS, 'api/v1/dashboard/reviews',);
};

export const uploadReview = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/review/add', data);
};

export const deleteReview = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/review/${data?.['_id']}/delete`,
      fetchReviews,
      data
    );
};

// Vendor routes
export const fetchVendors = () => {
  return (dispatch) =>
    fetchAllItems(dispatch, FETCH_VENDORS, 'api/v1/dashboard/vendors',);
};

export const uploadVendor = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/vendor/add', data);
};

export const updateVendor = (data) => {
  return (dispatch) =>
    updateOneItem(
      dispatch,
      `api/v1/dashboard/vendor/${data?.['_id']}/update`,
      fetchVendors,
      data
    );
};

export const deleteVendor = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/vendor/${data?.['_id']}/delete`,
      fetchVendors,
      data
    );
};

// Vendor resource routes
export const fetchResources = (owner) => {
  return (dispatch) =>
    fetchAllItems(
      dispatch,
      FETCH_RESOURCES,
      `api/v1/dashboard/resources?owner=${owner}`,
    );
};

export const uploadResource = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/resource/add', data);
};

export const updateResource = (data, id, owner) => {
  return (dispatch) =>
    updateOneItem(
      dispatch,
      `api/v1/dashboard/resource/${id}/update`,
      () => fetchResources(owner),
      data
    );
};

export const deleteResource = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/resource/${data?.['_id']}/delete`,
      fetchVendors,
      data
    );
};

// User actions
export const fetchUsers = () => {
  return (dispatch) =>
    fetchAllItems(dispatch, FETCH_USERS, 'api/v1/dashboard/users',);
};

export const addUser = (data) => {
  return (dispatch) => addItem(dispatch, 'api/v1/dashboard/user/add', data);
};

export const verifyUser = (data) => {
  return (dispatch) => {
    const url = `${apiUrl}/api/v1/dashboard/user/${data?.['_id']}/verify`;
    dispatch({
      type: LOADING,
    });
    axios({
      method: 'post',
      url,
      data,
    })
      .then((result) => {
        success(result);
        dispatch(fetchUsers());
      })
      .catch((err) => {
        failure(err);
        dispatch({
          type: READY,
        });
      });
  };
};

export const updateUser = (data) => {
  return (dispatch) =>
    updateOneItem(
      dispatch,
      `api/v1/dashboard/user/${data?.['_id']}/update`,
      fetchUsers,
      data
    );
};

export const deleteUser = (data) => {
  return (dispatch) =>
    deleteOneItem(
      dispatch,
      `api/v1/dashboard/user/${data?.['_id']}/delete`,
      fetchUsers,
      data
    );
};
