import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { uploadResource } from '../../redux/actions/userActions';
import {
  mapToFormInput,
  FormButton,
  LoadingButton,
  FileUpload,
} from '../../components/form-components';
import { createInputData, inputHandler, fileSizeHandler } from '../../utility';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

const AddResource = () => {
  const classes = useStyles();
  const {
    profile: { id },
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    title: '',
    images: [],
    specification: '',
  };
  const [addResourceState, setAddResourceState] = useState(initialState);

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };
  const [upload, setUpload] = useState(uploadState);

  const onchangeHandler = (e) => {
    inputHandler(e, setAddResourceState);
  };

  const resetHandler = () => {
    setAddResourceState(initialState);
    document.getElementById('myForm').reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    const { images, title, specification } = addResourceState;

    if (title && specification && images.length) {
      images.forEach((image) => {
        formData.append('images', image);
      });
      formData.append('title', title);
      formData.append('specification', specification);
      formData.append('owner', id);
      formData.append('createdBy', id);

      dispatch(uploadResource(formData));
      resetHandler();
    } else {
      swal({
        title: 'Hey!',
        text: 'complete all fields',
        icon: 'warning',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New Resource</p>
          {createInputData(
            { title: addResourceState.title },
            onchangeHandler
          )?.map(mapToFormInput)}
          <FileUpload
            id="images"
            name="images"
            upload={upload}
            value={addResourceState.images}
            handler={(event) =>
              fileSizeHandler(
                event,
                onchangeHandler,
                setUpload,
                uploadState,
                3000000
              )
            }
          />
          <textarea
            style={{
              margin: '25px 30px',
              display: 'block',
              width: '100%',
              // minWidth: 500,
              maxWidth: 500,
              minHeight: 300,
              maxHeight: 500,
            }}
            name="specification"
            value={addResourceState.specification}
            onChange={onchangeHandler}
            placeholder="specification..."
          />
          {loading && <LoadingButton content="Submitting Resource..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddResource;
