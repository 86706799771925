import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MuiModal } from '../../components/modals';
import {
  deleteVendor,
  updateVendor,
  fetchVendors,
} from '../../redux/actions/userActions';
import { mapToFormInput } from '../../components/form-components';
import { inputHandler, updateItemState, createInputData } from '../../utility';
import { VendorTable } from '../../components/table';

const ViewVendors = () => {
  const {
    profile: { id},
    vendors,
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    firstName: '',
    lastName: '',
    phone: '',
    businessName: '',
    bank: '',
    accountNumber: '',
    accountName: '',
    address: '',
    city: '',
    state: '',
  };

  const [vendorState, setVendorState] = useState(initialState);
  const [updateVendorModal, setUpdateVendorModal] = useState(false);

  useEffect(() => {
    dispatch(fetchVendors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteVendor = (data) => {
    dispatch(deleteVendor(data));
  };

  const onchangeHandler = (e) => {
    inputHandler(e, setVendorState);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const data = { ...vendorState, ...{ updatedBy: id } };
    dispatch(updateVendor(data));
  };

  const toggleUpdateVendorModal = () => {
    setUpdateVendorModal(!updateVendorModal);
  };

  const updateVendorState = (data) => {
    updateItemState(
      data,
      setVendorState,
      toggleUpdateVendorModal,
      initialState
    );
  };

  const renderUpdateVendor = () => {
    return (
      <div>
        {createInputData(vendorState, onchangeHandler)?.map(mapToFormInput)}
      </div>
    );
  };

  return (
    <div className="animated fadeIn">
      <VendorTable
        loading={loading}
        filterTarget="#filterModal"
        rows={vendors || ''}
        action={{
          edit: updateVendorState,
          delete: handleDeleteVendor,
        }}
      />

      <MuiModal
        open={updateVendorModal}
        handleClose={toggleUpdateVendorModal}
        title="Update Vendor"
        content={renderUpdateVendor}
        handler={submitHandler}
      />
    </div>
  );
};

export default ViewVendors;
