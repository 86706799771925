{/* eslint-disable-line react/no-array-index-key */}
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import { CustomCard } from '../../components/cards';
import { MuiModal } from '../../components/modals';
import {
  inputHandler,
  updateItemState,
  createInputData,
  fileSizeHandler,
} from '../../utility';
import {
  fetchGifts,
  updateGift,
  deleteGift,
} from '../../redux/actions/userActions';
import {
  mapToFormInput,
  mapToFormSelect,
  FileUpload,
} from '../../components/form-components';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
  },
  cardPane: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  cardGrid: {
    padding: theme.spacing(0),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  footer: {
    width: '100%',
  },
}));

const ViewGifts = () => {
  const classes = useStyles();
  const {
    profile: { id },
    gifts,
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const initialState = {
    title: '',
    description: '',
    specification: {
      size: '',
      quantity: '',
      weight: '',
    },
    price: '',
    category: '',
    size: '',
    images: [],
    _id: '',
  };

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };

  const [giftState, setGiftState] = useState(initialState);
  const [updateGiftModal, setUpdateGiftModal] = useState(false);
  const [upload, setUpload] = useState(uploadState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(fetchGifts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteGift = (data) => {
    dispatch(deleteGift(data));
  };

  const onchangeHandler = (e) => {
    const { name, value } = e.target;

    if (name.includes('spec')) {
      const realName = name.slice(4).toLowerCase();
      return setGiftState((prevVal) => ({
        ...prevVal,
        specification: {
          ...prevVal.specification,
          [realName]: value,
        },
      }));
    }
    return inputHandler(e, setGiftState);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const {
      title,
      description,
      specification,
      price,
      category,
      size,
      images,
    } = giftState;

    images &&
      images.forEach((image) => {
        formData.append('images', image);
      });
    title && formData.append('title', title);
    description && formData.append('description', description);
    specification &&
      formData.append('specification', JSON.stringify(specification));
    price && formData.append('price', price);
    category && formData.append('category', category);
    size && formData.append('size', size);
    formData.append('updatedBy', id);
    dispatch(updateGift(formData, giftState['_id']));
  };

  const toggleUpdateGiftModal = () => {
    setUpdateGiftModal(!updateGiftModal);
  };

  const updateGiftState = (data) => {
    updateItemState(data, setGiftState, toggleUpdateGiftModal, initialState);
  };

  // Render functions

  const renderUpdateGift = () => {
    return (
      <div>
        {createInputData(
          {
            title: giftState.title,
            description: giftState.description,
            price: giftState.price,
            specQuantity: giftState.specification?.quantity,
            specSize: giftState.specification?.size,
            specWeight: giftState.specification?.weight,
          },
          onchangeHandler
        )?.map(mapToFormInput)}
        {createInputData(
          {
            category: giftState.category,
            size: giftState.size,
          },
          onchangeHandler,
          {
            category: [
              {
                name: 'Select Category',
                value: '',
                enabled: false,
              },
              {
                name: 'Electronics',
                value: 'electronics',
                enabled: true,
              },
              {
                name: 'Cosmetics',
                value: 'cosmetics',
                enabled: true,
              },
              {
                name: 'Fashion',
                value: 'fashion',
                enabled: true,
              },
            ],
            size: [
              {
                name: 'Select Size',
                value: '',
                enabled: true,
              },
              {
                name: 'Small',
                value: 'small',
                enabled: true,
              },
              {
                name: 'Big',
                value: 'big',
                enabled: true,
              },
            ],
          }
        )?.map(mapToFormSelect)}

        <FileUpload
          id="images"
          name="images"
          upload={upload}
          value={giftState.images}
          handler={(event) =>
            fileSizeHandler(
              event,
              onchangeHandler,
              setUpload,
              uploadState,
              3000000
            )
          }
        />

        {createInputData(
          {
            createdBy: giftState.createdBy,
            updatedBy: giftState.updatedBy,
          },
          onchangeHandler
        )?.map(mapToFormInput)}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <Typography gutterBottom className={clsx('top-radius', classes.header)}>
          Gifts
        </Typography>
        <Container className={classes.cardPane} maxWidth="lg">
          <Grid container className={classes.cardGrid} spacing={2}>
            {loading ? (
              <div>Loading...</div>
            ) : (
              gifts &&
              gifts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((gift, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      item="gift"
                      items={[
                        {
                          modal: true,
                          name: 'Edit',
                          action: () => updateGiftState(gift),
                        },
                        {
                          modal: true,
                          name: 'Delete',
                          action: () => handleDeleteGift({ _id: gift._id }),
                        },
                      ]}
                      images={gift.images}
                      content={[
                        gift.title,
                        gift.description,
                        gift.category,
                        gift.price,
                      ]}
                      deleteUrl={`api/v1/dashboard/gift/${gift['_id']}/image/delete`}
                    />
                  </Grid>
                ))
            )}
          </Grid>
        </Container>
        <TablePagination
          className={classes.footer}
          labelRowsPerPage="Gifts per page"
          rowsPerPageOptions={[4, 8, 16]}
          component="div"
          count={gifts?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <MuiModal
        open={updateGiftModal}
        handleClose={toggleUpdateGiftModal}
        title="Update Gift"
        content={renderUpdateGift}
        handler={submitHandler}
      />
    </div>
  );
};

export default ViewGifts;
