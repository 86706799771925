import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';
import { MuiModal } from '../../components/modals';
import { confirmAlert } from '../../components/custom-alerts';
import {
  fetchUsers,
  verifyUser,
  updateUser,
  deleteUser,
} from '../../redux/actions/userActions';
import { FormButton, FormSelect } from '../../components/form-components';
import {
  inputHandler,
  updateItemState,
  numericPermission,
} from '../../utility';
import { UserTable } from '../../components/table';

const useStyles = makeStyles((theme) => ({
  verificationBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    width: '100%',
  },
  accessLevelLabel: {
    display: 'flex',
    justifyContent: 'center',
  },
  accessLevel: {
    display: 'flex',
    justifyContent: 'center',

    },
  verificationIcon: {
    margin: '0 calc(50% - 20px) 5px',
    fontSize: 40,
    color: '#e6e6e6',
  },
  verificationMessage: {
    color: '#c7c7c7',
  },
}));

const ViewUsers = () => {
  const classes = useStyles();
  const {
    profile: { id, accessLevel},
    users,
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isVerified: '',
    address: '',
    google: '',
    facebook: '',
  };

  const [userState, setUserState] = useState(initialState);
  const [updateUserModal, setUpdateUserModal] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteUser = (data) => {
    dispatch(deleteUser(data));
  };

  const onchangeHandler = (e) => {
    inputHandler(e, setUserState);
  };

  const toggleUpdateUserModal = () => {
    setUpdateUserModal(!updateUserModal);
  };

  const updateuserState = (data) => {
    updateItemState(data, setUserState, toggleUpdateUserModal, initialState);
  };

  const sendVerification = (e) => {
    e.preventDefault();
    confirmAlert('Send a verification mail to this user?', () => {
      const { email } = userState;
      dispatch(verifyUser({ email }));
      toggleUpdateUserModal();
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const data = { ...userState, ...{ updatedBy: id } };
    dispatch(updateUser(data));
  };

  const isUser = id === userState._id;
  // const canChangePermission = isUser || accessLevel > userState.accessLevel;
  // const canChangePermission = accessLevel >= userState.accessLevel && accessLevel >2;
  // const canChangePermission = accessLevel === 2 || (accessLevel > userState.accessLevel);
  const canChangePermission = isUser ||
  (accessLevel === 2 && userState.accessLevel < 4) || // Super admin can change access level of any user with access level < 2
  (accessLevel > 3 && userState.accessLevel < accessLevel); // Boss can change access level of any user with lower access level

  const renderUpdateUser = () => {
    return (
      <div>
        {!userState.isVerified && (
          <div className={classes.verificationBox}>
            <div className={classes.verificationMessageBox}>
              <ErrorOutlineIcon className={classes.verificationIcon} />
              <Typography
                className={classes.verificationMessage}
                align="center"
              >
                User is not verified
              </Typography>
            </div>
            <FormButton
              content="Send Verification"
              handler={sendVerification}
            />
          </div>
        )}
        {/* <div className={classes.accessLevelContainer}>
        <Typography className={classes.accessLevelLabel}>Access Level:</Typography>
        <div className={classes.accessLevel}>
          <FormButton
            content="-"
            handler={() => {
              setUserState(prevState => ({...prevState, accessLevel: numericPermission(prevState.accessLevel) - 1}));
            }}
            disabled={userState.accessLevel <= 0 || (!canChangePermission)}
          />
          <Typography className={classes.accessLevelValue}>{userState.accessLevel}</Typography>
          <FormButton
            content="+"
            handler={() => {
              setUserState(prevState => ({...prevState, accessLevel: numericPermission(prevState.accessLevel) + 1}));
            }}
            disabled={userState.accessLevel >= 4 || (!canChangePermission)}
          />
        </div>
      </div> */}
         <FormSelect
          enableFirst={true}
          labelContent="Access Level"
          id="accessLevel"
          name="accessLevel"
          value={numericPermission(userState.accessLevel)}
          handler={onchangeHandler}
          options={[
            {
              name: 'User',
              value: 1,
              enabled: true,
            },
            {
              name: 'Developer',
              value: 2,
              enabled: accessLevel > 2 ,
            },
            {
              name: 'Admin',
              value: 3,
              enabled: accessLevel > 2,
            },
            {
              name: 'Super Admin',
              value: 4,
              enabled: accessLevel > 1 && canChangePermission,
            },
          ]}
        /> 
      </div>
    );
  };

  return (
    <div className="animated fadeIn">
      <UserTable
        loading={loading}
        rows={users || ''}
        adminAccess={accessLevel}
        adminId={id}
        action={{
          edit: updateuserState,
          delete: handleDeleteUser,
        }}
      />
      <MuiModal
        open={updateUserModal}
        handleClose={toggleUpdateUserModal}
        title="Update User"
        content={renderUpdateUser}
        handler={submitHandler}
      />
    </div>
  );
};

export default ViewUsers;
