import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import RedeemIcon from '@material-ui/icons/Redeem';
import CakeIcon from '@material-ui/icons/Cake';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PersonIcon from '@material-ui/icons/Person';
import RateReviewIcon from '@material-ui/icons/RateReview';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(() => ({
  IconTheme: {
    color: '#55c2d9',
  },
  ltgray: {
    color: '#b1b3af',
  },
  list: {
    color: 'black',
    '&:hover': {
      background: '#F5F5F5',
    },
  },
  subList: {
    margin: '0',
    padding: '4px 4px 2px 30px',
    color: '#f9f9f9',
  },
  subIcon: {
    fontSize: '20px',
    color: 'white',
  },
  subText: {
    fontSize: '16px',
    margin: '0',
    color: '#9e9d9d',
    '&:hover': {
      color: '#9e9d9d',
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
      color: '#c7c7c7',
    },
    textDecoration: 'none',
  },
}));

const SideBarContent = () => {
  const classes = useStyles();
  const {
    profile: { accessLevel },
  } = useSelector((state) => state);
  const [openOrders, setOpenOrders] = React.useState(false);
  const [openCakes, setOpenCakes] = React.useState(false);
  const [openGifts, setOpenGifts] = React.useState(false);
  const [openEmails, setOpenEmails] = React.useState(false);
  const [openVendors, setOpenVendors] = React.useState(false);
  const [openUsers, setOpenUsers] = React.useState(false);
  const [openReviews, setOpenReviews] = React.useState(false);
  const [openResource, setOpenResource] = React.useState(false);
  const [openMarket, setOpenMarket] = React.useState(false);

  const handleOrders = () => {
    setOpenOrders((prevValue) => !prevValue);
  };
  const handleCakes = () => {
    setOpenCakes((prevValue) => !prevValue);
  };
  const handleMarket = () => {
    setOpenMarket((prevValue) => !prevValue);
  };
  const handleGifts = () => {
    setOpenGifts((prevValue) => !prevValue);
  };
  const handleEmails = () => {
    setOpenEmails((prevValue) => !prevValue);
  };
  const handleVendors = () => {
    setOpenVendors((prevValue) => !prevValue);
  };
  const handleUsers = () => {
    setOpenUsers((prevValue) => !prevValue);
  };
  const handleReviews = () => {
    setOpenReviews((prevValue) => !prevValue);
  };
  const handleResource = () => {
    setOpenResource((prevValue) => !prevValue);
  };

  return (
    <div>
      <List>
        <ListItem
          component={Link}
          to="/"
          key="Dashboard"
          className={classes.list}
          style={{ textDecoration: 'none' }}
        >
          <ListItemIcon>
            <DashboardIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </List>
      <Divider />
      <List>
        {/* Orders List */}
        <ListItem button key="Orders" onClick={handleOrders}>
          <ListItemIcon>
            <MonetizationOnIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Orders" />
          {openOrders ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
        </ListItem>
        <Collapse in={openOrders} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {(accessLevel == 1 || accessLevel > 2 ) && (
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/addOrders"
                href="ui-buttons.html"
                className={classes.subText}
              >
                create order
              </Link>
            </ListItem>)}
          {(accessLevel > 1 ) && (
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/orders"
                href="ui-buttons.html"
                className={classes.subText}
              >
                show orders
              </Link>
            </ListItem>)}
          </List>
        </Collapse>

        {/*  Cakes List */}
        <ListItem button key="Cakes" onClick={handleCakes}>
          <ListItemIcon>
            <CakeIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Cakes" />
          {openCakes ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
        </ListItem>
        <Collapse in={openCakes} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {(accessLevel == 1 || accessLevel > 2 ) && (
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/addCakes"
                href="ui-buttons.html"
                className={classes.subText}
              >
                add cake
              </Link>
            </ListItem>)}
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/viewCakes"
                href="ui-buttons.html"
                className={classes.subText}
              >
                show cakes
              </Link>
            </ListItem>
          </List>
        </Collapse>

        {/*  Gifts List */}
        <ListItem button key="Gifts" onClick={handleGifts}>
          <ListItemIcon>
            <RedeemIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Gifts" />
          {openGifts ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
        </ListItem>
        <Collapse in={openGifts} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {(accessLevel == 1 || accessLevel > 2 ) && (
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link to="/addGifts" className={classes.subText}>
                add gift
              </Link>
            </ListItem>)}
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link to="/viewGifts" className={classes.subText}>
                show gifts
              </Link>
            </ListItem>
          </List>
        </Collapse>

        {/*  Vendors List */}
        <ListItem button key="Vendors" onClick={handleVendors}>
          <ListItemIcon>
            <StorefrontIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Vendors" />
          {openVendors ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
        </ListItem>
        <Collapse in={openVendors} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {( accessLevel > 2 ) && (
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/addVendor"
                href="ui-buttons.html"
                className={classes.subText}
              >
                add vendor
              </Link>
            </ListItem>)}
          {( accessLevel > 1 ) && (
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/viewVendors"
                href="ui-buttons.html"
                className={classes.subText}
              >
                show vendors
              </Link>
            </ListItem>)}
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        {/* Users List */}
        <ListItem button key="Users" onClick={handleUsers}>
          <ListItemIcon>
            <PersonIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Users" />
          {openUsers ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
        </ListItem>
        <Collapse in={openUsers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {accessLevel > 2 && (
              <ListItem button className={classes.subList}>
                <ListItemIcon></ListItemIcon>
                <Link to="/addUsers" className={classes.subText}>
                  add user
                </Link>
              </ListItem>
            )}
            {accessLevel > 3 && ( 

            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/viewUsers"
                href="ui-buttons.html"
                className={classes.subText}
              >
                show users
              </Link>
            </ListItem>
          )}
          </List>
        </Collapse>

        {/* Reviews List */}
        <ListItem button key="Reviews" onClick={handleReviews}>
          <ListItemIcon>
            <RateReviewIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Reviews" />
          {openReviews ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
        </ListItem>
        <Collapse in={openReviews} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/addReviews"
                href="ui-buttons.html"
                className={classes.subText}
              >
                add review
              </Link>
            </ListItem>
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/viewReviews"
                href="ui-buttons.html"
                className={classes.subText}
              >
                show reviews
              </Link>
            </ListItem>
          </List>
        </Collapse>

        {/* Resource Center */}
        <ListItem button key="Resource Center" onClick={handleResource}>
          <ListItemIcon>
            <StorefrontIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Resource Center" />
          {openResource ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
        </ListItem>
        <Collapse in={openResource} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {accessLevel > 1 && ( 
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/addResource"
                href="ui-buttons.html"
                className={classes.subText}
              >
                add resource
              </Link>
            </ListItem>)}
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/viewResources"
                href="ui-buttons.html"
                className={classes.subText}
              >
                show resource
              </Link>
            </ListItem>
          </List>
        </Collapse>
      </List>
      <Divider />
         {/*  Item List */}
         <ListItem button key="Market Place" onClick={handleMarket}>
          <ListItemIcon>
            <StorefrontIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Market Place" />
          {openMarket ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
          </ListItem>
        <Collapse in={openMarket} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {(accessLevel == 1 || accessLevel > 2 ) && (
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/addMarketItems"
                href="ui-buttons.html"
                className={classes.subText}
              >
                add item
              </Link>
            </ListItem>)}
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link
                to="/viewMarketItems"
                href="ui-buttons.html"
                className={classes.subText}
              >
                show items
              </Link>
            </ListItem>
          </List>
        </Collapse>
        <Divider />

         {/*  email List */}
         <ListItem button key="Emails" onClick={handleEmails}>
          <ListItemIcon>
            <RedeemIcon className={classes.IconTheme} />
          </ListItemIcon>
          <ListItemText primary="Emails" />
          {openEmails ? (
            <ExpandLessIcon className={classes.ltgray} />
          ) : (
            <ExpandMoreIcon className={classes.ltgray} />
          )}
        </ListItem>
        <Collapse in={openEmails} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {(accessLevel == 1 || accessLevel > 2 ) && (
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link to="/addEmails" className={classes.subText}>
                add email
              </Link>
            </ListItem>)}
            <ListItem button className={classes.subList}>
              <ListItemIcon></ListItemIcon>
              <Link to="/viewEmails" className={classes.subText}>
                show emails
              </Link>
            </ListItem>
          </List>
        </Collapse>
    </div>
  );
};

export default SideBarContent;
