{/* eslint-disable-line react/no-array-index-key */}
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { createOrder } from '../../redux/actions/userActions';
import {
  mapToFormInput,
  mapToFormSelect,
  FormInput,
  FormButton,
  LoadingButton,
  DateTimeInput,
  ImageUpload,
} from '../../components/form-components';
import { createInputData, fileSizeHandler, inputHandler, styleInput } from '../../utility';
import '../../app-assets/styles/css/style.css';
import './styles/css/addOrder.css';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  formRoot: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightMargin: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  miniHeader: {
    margin: theme.spacing(0, 2, 2, 0),
    fontWeight: 'bold',
    color: '#ababab',
  },
  spacing: {
    margin: theme.spacing(6, 3, 2),
  },
  participantWrapper: {
    padding: theme.spacing(2, 3),
  },
  participant: {
    border: '1px solid #e6e6e6',
    margin: theme.spacing(2, 0),
  },
  participantPagination: {
    margin: theme.spacing(1, 3, 3),
    fontWeight: '600',
    color: '#c7c7c7',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  participantButtons: {
    backgroundColor: 'white',
    color: '#ababab',
    marginRight: '5px',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

// const inputsHandler = (event, setItemState) => {
//   const { name, value, file, files } = event.target;
//   if (file) {
//     setItemState(() => {
//       return;
//     });
//   }
//   if (files) {
//     setItemState((prevValue) => {
//       const newValue = Array.isArray(prevValue[name])
//       ? [...prevValue[name], ...files]
//       : [files];
//     return {
//       ...prevValue,
//       [name]: newValue,};
//     });
//   } else {
//     const finalValue = styleInput(name, value);
//     switch (name) {
//       case 'payStatus': {
//         return setItemState((prevValue) => {
//           const hasPaid = finalValue.toLowerCase() === 'successful';
//           return {
//             ...prevValue,
//             payment: { ...prevValue.payment, status: finalValue, hasPaid },
//           };
//         });
//       }
//       case 'payMethod': {
//         return setItemState((prevValue) => {
//           const onDelivery = finalValue.toLowerCase() === 'pay on delivery';
//           return {
//             ...prevValue,
//             payment: { ...prevValue.payment, method: finalValue, onDelivery },
//           };
//         });
//       }
//       case 'recipientName': {
//         return setItemState((prevValue) => {
//           return {
//             ...prevValue,
//             recipient: { ...prevValue.recipient, name: finalValue },
//           };
//         });
//       }
//       case 'recipientPhone': {
//         return setItemState((prevValue) => {
//           return {
//             ...prevValue,
//             recipient: { ...prevValue.recipient, phone: finalValue },
//           };
//         });
//       }
//       case 'recipientAddress': {
//         return setItemState((prevValue) => {
//           return {
//             ...prevValue,
//             recipient: { ...prevValue.recipient, address: finalValue },
//           };
//         });
//       }
//       default: {
//         return setItemState((prevValue) => {
//           return { ...prevValue, [name]: finalValue };
//         });
//       }
//     }
//   }
// };

const AddOrders = () => {
  const classes = useStyles();

  const {
    profile: { id },
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const initialOrderState = {
    name: '',
    email: '',
    phone: '',
    title: '',
    description: '',
    deliveryDateTime: new Date(new Date().setHours(new Date().getHours() + 12))
    .toISOString().slice(0, 10) + " / " + new Date(new Date().setHours(new Date().getHours() + 12)).toISOString().slice(11, 16),
    recipient: {
      name: '',
      phone: '',
      address: '',
      gender: '',
      country: '',
    },
    custom: '',
    color: '',
    payment: {
      method: '',
      status: 'pending',
      onDelivery: false,
      hasPaid: false,
      shared: false,
    },
    status: 'pending',
    costPrice: '',
    logisticsCost: '',
    sellingPrice: '',
    orderType: 'regular',
    createdBy: '',
    flavour: '',
    frosting: '',
    colour: [],
    orderSource: '',
    captureSource: '',
    quantity: Number,
    cakeId: '',
    ownerId: '',
    product_Id: '',
    size: '',
    deliveryState: '',
    deliveryTime: '',
    // captureVideo: Boolean,
    gender: '',
    city: '',
    country: '',
    referenceId: '',
    slackThreadId: '' ,
  };

  const additionalItemsDefault = {
    selected: false,
    display: 'none',
    additionalItems: [{
      productName: '',
      product_Id: '',
      productImageUrl: '',
      quantity: '',
      unitPrice: '',
      totalPrice: '',
    }],
  };

  const shareMyBillDefault = {
    selected: false,
    display: 'none',
    participants: [
      {
        name: '',
        email: '',
        phone: '',
        amount: '',
      },
    ],
  };

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };

  
  const [orderState, setOrderState] = useState(initialOrderState);
  const [shareMyBill, setShareMyBill] = useState(shareMyBillDefault);
  const [addItems, setadditionalItems] = useState(additionalItemsDefault);
  const [upload, setUpload] = useState(uploadState);

  const splitBill = (price, arr) => {
    if (price && arr.length) {
      const amount = (price.replace(/[^0-9]/g, '') / arr.length).toFixed(2);
      arr.forEach((entry) => {
        entry.amount = styleInput('amount', amount);
      });
    }
  };

  const onchangeHandler = (e) => {
    inputHandler(e, setadditionalItems);
    inputHandler(e, setOrderState);
    if (e.target.name === 'sellingPrice')
    splitBill(e.target.value, shareMyBill.participants);
  };
  
  
  const additionalItemChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const clone = addItems.additionalItems.slice();
    clone[index][name] = styleInput(name, value);
    
    inputHandler(e, setadditionalItems);
    setadditionalItems((prevVal) => {
      // setOrderState((prevVal) => {
      return {
        ...prevVal,
        additionalItems: clone,
      };
    });
  };

  const participantChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const clone = shareMyBill.participants;
    clone[index][name] = styleInput(name, value);

    setShareMyBill((prevVal) => {
      return {
        ...prevVal,
        participants: clone,
      };
    });
  };

  const itemToggleSMB = (e) => {
    const { checked } = e.target;
    if (checked) {
      setadditionalItems((prevVal) => {
        return {
          ...prevVal,
          selected: true,
          display: 'block',
        };
      });
    } else {
      setadditionalItems(additionalItemsDefault);
    }
  };

  const toggleSMB = (e) => {
    const { checked } = e.target;
    if (checked) {
      splitBill(orderState.sellingPrice, shareMyBill.participants);
      setShareMyBill((prevVal) => {
        return {
          ...prevVal,
          selected: true,
          display: 'block',
        };
      });
    } else {
      setShareMyBill(shareMyBillDefault);
    }
  };

  const addNewItem = (e) => {
    e.preventDefault();
    const newItem = {
      productImageUrl: '',
      product_Id: '',
      productName:'',
      quantity:'',
      unitPrice:'',
      totalPrice: '',
    };
    const cloneAdditionalItems = addItems.additionalItems;
    cloneAdditionalItems.push(newItem);
    setadditionalItems((prevVal) => {
      return { ...prevVal, additionalItems: cloneAdditionalItems };
    });
    console.log(addItems);
  };
  
  const addParticipant = (e) => {
    e.preventDefault();
    const newParticipant = {
      name: '',
      email: '',
      phone: '',
    };
    const cloneParticipants = shareMyBill.participants;
    cloneParticipants.push(newParticipant);
    setShareMyBill((prevVal) => {
      return { ...prevVal, participants: cloneParticipants };
    });
    splitBill(orderState.sellingPrice, shareMyBill.participants);
  };

  const deleteNewItem = (e) => {
    e.preventDefault();
    const currentAdditionalItems = addItems.additionalItems;
    currentAdditionalItems.pop();
    setadditionalItems((prevVal) => {
      return { ...prevVal, additionalItems: currentAdditionalItems };
    });
  };

  const deleteParticipant = (e) => {
    e.preventDefault();
    const currentParticipants = shareMyBill.participants;
    currentParticipants.pop();
    setShareMyBill((prevVal) => {
      return { ...prevVal, participants: currentParticipants };
    });
    splitBill(orderState.sellingPrice, shareMyBill.participants);
  };

  const resetHandler = () => {
    setUpload(uploadState);
    setOrderState(initialOrderState);
    setShareMyBill(shareMyBillDefault);
    setadditionalItems(additionalItemsDefault);
    document.getElementById('smbButton').checked = false;
    document.getElementById('myForm').reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    
    const data = { ...orderState, ...addItems, ...{ createdBy: id } };
    
    const itemsArray = addItems.additionalItems.map((additionalItem) => {
      return {
        productName: additionalItem.productName,
        quantity: additionalItem.quantity,
        unitPrice: additionalItem.unitPrice,
        totalPrice: additionalItem.totalPrice,
        productImageUrl: additionalItem.productImageUrl,
      };
    });
    data.itemsArray = itemsArray;
    
    if(shareMyBill.selected) {
    let incomplete = false;
      shareMyBill.participants?.forEach((participant) => {
        if (!participant.name) {
          incomplete = true;
          return alert('Participant name is required');
        }
        if (!(participant.email || participant.phone)) {
          incomplete = true;
          return alert('Participant email or phone is required');
        }
      });
      if (incomplete) return 0;
      data.payment.shared = true;
      data.participants = shareMyBill.participants;
      data.orderType = 'share my bill';
    }
    if (addItems.selected) {
      const updatedAdditionalItems = addItems.additionalItems.map((additionalItem) => {
        return {...additionalItem, productImageUrl: additionalItem.productImageUrl};
      });
      data.additionalItems = updatedAdditionalItems;
      console.log(data.additionalItems);
      }
      
    console.log(data);
    dispatch(createOrder(data));
    resetHandler();
  };

  const ItemChangeHandler = (index) => (e) => {
    additionalItemChangeHandler(e, index);
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New Order</p>
          <p className={classes.subheader}>Sender Details</p>
          <Divider />

          {createInputData(
            {
              name: orderState.name,
              email: orderState.email,
              phone: orderState.phone,
              country: orderState.country,
            },
            onchangeHandler
          )?.map(mapToFormInput)}

          {createInputData(
            {
              gender: orderState.gender,
            },
            onchangeHandler,
            {
              gender: [
                {
                  name: 'Select Gender',
                  value: '',
                  enabled: false,
                },
                {
                  name: 'Male',
                  value: 'male',
                  enabled: true,
                },
                {
                  name: 'Female',
                  value: 'female',
                  enabled: true,
                },
              ],
             
            }
          )?.map(mapToFormSelect)}

          <p className={classes.subheader}>Recipient Details</p>
          <Divider />

          {createInputData(
            {
              recipientName: orderState.recipient.name,
              recipientPhone: orderState.recipient.phone,
              recipientAddress: orderState.recipient.address,
              recipientCountry: orderState.recipient.country,
            },
            onchangeHandler
          )?.map(mapToFormInput)}

          {createInputData(
            {
              recipientGender: orderState.recipient.gender,
            },
            onchangeHandler,
            {
              recipientGender: [
                {
                  name: 'Select Gender',
                  value: '',
                  enabled: false,
                },
                {
                  name: 'Male',
                  value: 'male',
                  enabled: true,
                },
                {
                  name: 'Female',
                  value: 'female',
                  enabled: true,
                },
              ],
             
            }
          )?.map(mapToFormSelect)}

          <p className={classes.subheader}>Order Details</p>
          <Divider />

          {createInputData(
            {
              title: orderState.title,
              description: orderState.description,
              quantity: orderState.quantity,
              cakeId: orderState.cakeId,
              ownerId: orderState.ownerId,
              product_Id: orderState.product_Id,
              referenceId: orderState.referenceId,
              slackThreadId: orderState.slackThreadId,
              captureSource: orderState.captureSource,
              city: orderState.city,
              costPrice: orderState.costPrice,
              logisticsCost: orderState.logisticsCost,
              sellingPrice: orderState.sellingPrice,
            },
            onchangeHandler
          )?.map(mapToFormInput)}
        
          <FormInput
            htmlFor="inscription"
            labelContent="inscription"
            id="custom"
            name="custom"
            placeHolder="inscription"
            value={orderState.custom}
            handler={onchangeHandler}
            disabled={false}
          />

          {createInputData(
            {
              size: orderState.size,
              frosting: orderState.frosting,
              flavour: orderState.flavour,
              orderSource: orderState.orderSource,
              deliveryState: orderState.deliveryState,
              payMethod: orderState.payment.method,
              payStatus: orderState.payment.status,
            },
            onchangeHandler,
            {
              payMethod: [
                {
                  
                  name: 'Select Pay Method',
                  value: '',
                  enabled: false,
                },
                {
                  name: 'Bank Transfer',
                  value: 'bank transfer',
                  enabled: true,
                },
                {
                  name: 'Pay On Delivery',
                  value: 'pay on delivery',
                  enabled: true,
                },
              ],
              payStatus: [
                {
                  name: 'Select Status',
                  value: '',
                  enabled: false,
                },
                {
                  name: 'Pending',
                  value: 'pending',
                  enabled: true,
                },
                {
                  name: 'Success',
                  value: 'success',
                  enabled: true,
                },
              ],
              flavour: [
                {
                  name: 'Select Flavour',
                  value: '',
                  enabled: false,
                },
                {
                  name: ' vanilla',
                  value: 'vanilla',
                  enabled: true,
                },
                {
                  name: 'strawberry',
                  value: 'strawberry',
                  enabled: true,
                },
                {
                  name: 'chocolate',
                  value: 'chocolate',
                  enabled: true,
                },
                {
                  name: 'red-velvet',
                  value: 'red-velvet',
                  enabled: true,
                },
              ],
              frosting: [
                {
                  name: 'Select Frosting',
                  value: '',
                  enabled: false,
                },
                {
                  name: ' whippedcream',
                  value: 'whippedcream',
                  enabled: true,
                },
                {
                  name: 'fondant',
                  value: 'fondant',
                  enabled: true,
                },
                {
                  name: 'butter-icing',
                  value: 'butter-icing',
                  enabled: true,
                },
              ],
              size: [
                {
                  name: 'Select Size',
                  value: '',
                  enabled: false,
                },

                {
                  name: '8x4',
                  value: '8x4',
                  enabled: true,
                },
                {
                  name: '8x6',
                  value: '8x6',
                  enabled: true,
                },
                {
                  name: '8x8',
                  value: '8x8',
                  enabled: true,
                },
                {
                  name: '10x6',
                  value: '10x6',
                  enabled: true,
                },
                {
                  name: '12x6',
                  value: '12x6',
                  enabled: true,
                },
              ],
              orderSource: [
                {
                  name: 'Select Source',
                  value: '',
                  enabled: false,
                },
                {
                  name: 'web',
                  value: 'web',
                  enabled: true,
                },
                {
                  name: 'mobile',
                  value: 'mobile',
                  enabled: true,
                },
                {
                  name: 'whatsapp',
                  value: 'whatsapp',
                  enabled: true,
                },
              ],
              deliveryState: [
                {
                  name: 'Select State',
                  value: '',
                  enabled: false,
                },
                {
                  name:'Abia',
                  value: 'Abia',
                  enabled: true,
                },
                {
                  name:'Adamawa',
                  value: 'Adamawa',
                  enabled: true,
                },
                {
                  name:'Akwa Ibom',
                  value: 'Akwa Ibom',
                  enabled: true,
                },
                {
                  name:'Anambra',
                  value: 'Anambra',
                  enabled: true,
                },
                {
                  name:'Bauchi',
                  value: 'Bauchi',
                  enabled: true,
                },
                {
                  name:'Bayelsa',
                  value: 'Bayelsa',
                  enabled: true,
                },
                {
                  name:'Benue',
                  value: 'Benue',
                  enabled: true,
                },
                {
                  name:'Borno',
                  value: 'Borno',
                  enabled: true,
                },
                {
                  name:'Cross River',
                  value: 'Cross River',
                  enabled: true,
                },
                {
                  name:'Delta',
                  value: 'Delta',
                  enabled: true,
                },
                {
                  name:'Ebonyi',
                  value: 'Ebonyi',
                  enabled: true,
                },
                {
                  name:'Edo',
                  value: 'Edo',
                  enabled: true,
                },
                {
                  name:'Ekiti',
                  value: 'Ekiti',
                  enabled: true,
                },
                {
                  name:'Enugu',
                  value: 'Enugu',
                  enabled: true,
                },
                {
                  name:'FCT',
                  value: 'FCT',
                  enabled: true,
                },
                {
                  name:'Gombe',
                  value: 'Gombe',
                  enabled: true,
                },
                {
                  name:'Imo',
                  value: 'Imo',
                  enabled: true,
                },
                {
                  name:'Jigawa',
                  value: 'Jigawa',
                  enabled: true,
                },
                {
                  name:'Kaduna',
                  value: 'Kaduna',
                  enabled: true,
                },
                {
                  name:'Kano',
                  value: 'Kano',
                  enabled: true,
                },
                {
                  name:'Katsina',
                  value: 'Katsina',
                  enabled: true,
                },
                {
                  name:'Kebbi',
                  value: 'Kebbi',
                  enabled: true,
                },
                {
                  name:'Kogi',
                  value: 'Kogi',
                  enabled: true,
                },
                {
                  name:'Kwara',
                  value: 'Kwara',
                  enabled: true,
                },
                {
                  name:'Lagos',
                  value: 'Lagos',
                  enabled: true,
                },
                {
                  name:'Nasarawa',
                  value: 'Nasarawa',
                  enabled: true,
                },
                {
                  name:'Niger',
                  value: 'Niger',
                  enabled: true,
                },
                {
                  name:'Ogun',
                  value: 'Ogun',
                  enabled: true,
                },
                {
                  name:'Ondo',
                  value: 'Ondo',
                  enabled: true,
                },
                {
                  name:'Osun',
                  value: 'Osun',
                  enabled: true,
                },
                {
                  name:'Oyo',
                  value: 'Oyo',
                  enabled: true,
                },
                {
                  name:'Plateau',
                  value: 'Plateau',
                  enabled: true,
                },
                {
                  name:'Rivers',
                  value: 'Rivers',
                  enabled: true,
                },
                {
                  name:'Sokoto',
                  value: 'Sokoto',
                  enabled: true,
                },
                {
                  name:'Taraba',
                  value: 'Taraba',
                  enabled: true,
                },
                {
                  name:'Yobe',
                  value: 'Yobe',
                  enabled: true,
                },
                {
                  name:'Zamfara',
                  value: 'Zamfara',
                  enabled: true,
                },
              ]
            }
          )?.map(mapToFormSelect)}

          <DateTimeInput
            labelContent="Delivery Date/Time"
            value={orderState.deliveryDateTime}
            minimumTime={new Date(
              new Date().setHours(new Date().getHours() + 12)
            )
              .toISOString()
              .slice(0, 10) + " / " + new Date(
                new Date().setHours(new Date().getHours() + 12)
              ).toISOString().slice(11, 16)
            }
            handler={(time) => {
              setOrderState((prevVal) => {
                return { ...prevVal, deliveryDateTime: time };
              });
            }}/>

          <div className={clsx(classes.spacing, classes.row)}>
            <p className={clsx(classes.subheader, classes.rightMargin)}>
              Additional Items
            </p>
            <label className={clsx('switch', classes.rightMargin)}>
              <input id="smbButton" type="checkbox" onChange={itemToggleSMB} />
              <span className="slider round"></span>
            </label>
          </div>
          <Divider />
          <div
            className={classes.participantWrapper}
            style={{ display: addItems.display }}
          >
            <p className={classes.miniHeader}>Add Item</p>

            {addItems.additionalItems.map((additionalItem, index) => {
              return (
                <div
                  key={index}
                  className={clsx(classes.participant, 'radius')}
                >
                 <ImageUpload
                    id="productImageUrl"
                    name="productImageUrl"
                    upload={upload}
                    value={additionalItem.productImageUrl}
                    handler={(event) =>
                      fileSizeHandler(
                        event,
                        ItemChangeHandler(index),
                        setUpload,
                        uploadState,
                        3000000
                      )
                    }
                  />
                  {createInputData(
                    {
                      productName: additionalItem.productName,
                      productQuantity: additionalItem.productQuantity,
                      unitPrice: additionalItem.unitPrice,
                      totalPrice: additionalItem.totalPrice,
                    },
                    ItemChangeHandler(index),
                    // (e) => {
                    // additionalItemChangeHandler(e, index);}
                  )?.map(mapToFormInput)}
                  
                  <p className={classes.participantPagination}>{index + 1}</p>
                </div>
              ); 
            })} 
            <Button
              className={classes.participantButtons}
              variant="contained"
              // color="primary"
              onClick={addNewItem}
            >
              <AddIcon />
            </Button>
            {addItems.additionalItems?.length > 1 ? (
              <Button
                className={classes.participantButtons}
                variant="contained"
                onClick={deleteNewItem}
              >
                <DeleteIcon />
              </Button>
            ) : (
              ''
            )}
          </div>

          <div className={clsx(classes.spacing, classes.row)}>
            <p className={clsx(classes.subheader, classes.rightMargin)}>
              Share My Bill
            </p>
            <label className={clsx('switch', classes.rightMargin)}>
              <input id="smbButton" type="checkbox" onChange={toggleSMB} />
              <span className="slider round"></span>
            </label>
          </div>
          <Divider />
          <div
            className={classes.participantWrapper}
            style={{ display: shareMyBill.display }}
          >
            <p className={classes.miniHeader}>Participants</p>

            {shareMyBill.participants?.map((participant, index) => {
              return (
                <div
                  key={index}
                  className={clsx(classes.participant, 'radius')}
                >
                  {createInputData(
                    {
                      name: participant.name,
                      email: participant.email,
                      phone: participant.phone,
                      amount: participant.amount,
                    },
                    (e) => {
                      participantChangeHandler(e, index);
                    }
                  )?.map(mapToFormInput)}
                  <p className={classes.participantPagination}>{index + 1}</p>
                </div>
              );
            })}
            <Button
              className={classes.participantButtons}
              variant="contained"
              // color="primary"
              onClick={addParticipant}
            >
              <AddIcon />
            </Button>
            {shareMyBill.participants?.length > 1 ? (
              <Button
                className={classes.participantButtons}
                variant="contained"
                onClick={deleteParticipant}
              >
                <DeleteIcon />
              </Button>
            ) : (
              ''
            )}
          </div>
          {loading && <LoadingButton content="Submitting Order..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrders;
