import React from 'react';

const LoadingButton = (props) => {
  return (
    <button className="btn btn-primary" type="button" disabled>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>{' '}
      &nbsp;
      {props.content}
    </button>
  );
};

export { LoadingButton };
