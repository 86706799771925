import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const boxWidth = 600;
const mobileBoxWidth = 'calc(100vw - 64px)';
const mobileBoxHeight = 'calc((100vw - 64px) * 4/6)';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 180px)',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    height: mobileBoxHeight,
    width: mobileBoxWidth,
    [theme.breakpoints.up('sm')]: {
      height: (4 / 6) * boxWidth,
      maxWidth: boxWidth,
    },
  },
  imageBox: {
    height: '100%',
    maxWidth: '50%',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '100%',
    maxWidth: '50%',
    textAlign: 'center',
  },
  title: {
    fontSize: '3em',
    fontWeight: 1000,
    lineHeight: '1em',
    color: 'red',
    [theme.breakpoints.up('sm')]: {
      fontSize: '4em',
    },
  },
  caption: {
    fontSize: '1.5em',
    lineHeight: '1em',
    color: 'gray',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2em',
    },
  },
}));

const ErrorPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.contentBox}>
        <div className={classes.imageBox}>
          <img
            style={{ width: '100%', height: '100%' }}
            alt="cake"
            src="/img/404-image.png"
          />
        </div>
        <div className={classes.textBox}>
          <p className={classes.title}>404 Error!</p>
          <p className={classes.caption}>page not found</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
