import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const MailBadge = withStyles(() => ({
  badge: {
    right: 0,
    top: 0,
    border: `1px solid white`,
    padding: '0 4px',
    backgroundColor: '#5ec43f',
  },
}))(Badge);

export default MailBadge;
