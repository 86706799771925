import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { uploadCake } from '../../redux/actions/userActions';
import {
  mapToFormInput,
  mapToFormSelect,
  FormButton,
  FileUpload,
  LoadingButton,
} from '../../components/form-components';
import { createInputData, inputHandler, fileSizeHandler } from '../../utility';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

const AddCakes = () => {
  const classes = useStyles();
  const {
    profile: { id },
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    title: '',
    description: '',
    price: '',
    category: '',
    size: '',
    height: '',
    width: '',
    flavours: '',
    frosting: '',
    details: '',
    deliveryOptions: '',
    paymentOptions: '',
    metaTag: '',
    logistics: '',
    images: [],
  };

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };

  const [addCakeState, setAddCakeState] = useState(initialState);
  const [upload, setUpload] = useState(uploadState);

  const onchangeHandler = (e) => {
    inputHandler(e, setAddCakeState);
  };

  const resetHandler = () => {
    setAddCakeState(initialState);
    setUpload(uploadState);
    document.getElementById('myForm').reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const {
      images,
      title,
      description,
      price,
      category,
      size,
      flavours,
      frosting,
      details,
      metaTag,
      height,
      width,
      deliveryOptions,
      paymentOptions,
      logistics,
    } = addCakeState;
    images.forEach((image) => {
      formData.append('images', image);
    });

    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('category', category);
    formData.append('size', size);
    formData.append('flavours', flavours);
    formData.append('frosting', frosting);
    formData.append('details', details);
    formData.append('logistics', logistics);
    formData.append('height', height);
    formData.append('width', width);
    formData.append('deliveryOptions', deliveryOptions);
    formData.append('paymentOptions', paymentOptions);
    formData.append('metaTag', metaTag);
    formData.append('createdBy', id);

    if (title && price && images.length) {
      dispatch(uploadCake(formData));
      resetHandler();
    } else {
      swal({
        title: 'Hey!',
        text: 'complete all fields',
        icon: 'warning',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New Cake</p>
          {createInputData(
            {
              title: addCakeState.title,
              description: addCakeState.description,
              height: addCakeState.height,
              width: addCakeState.width,
              metaTag: addCakeState.metaTag,
              details: addCakeState.details,
              deliveryOptions: addCakeState.deliveryOptions,
              paymentOptions: addCakeState.paymentOptions,
              logistics: addCakeState.logistics,
              price: addCakeState.price,
            },
            onchangeHandler
          )?.map(mapToFormInput)}
          {createInputData(
            {
              category: addCakeState.category,
              size: addCakeState.size,
              frosting: addCakeState.frosting,
              flavours: addCakeState.flavours,
            },
            onchangeHandler,
            {
              category: [
                {
                  name: 'Select Category',
                  value: '',
                  enabled: false,
                },
                {
                  name: 'Regular',
                  value: 'regular',
                  enabled: true,
                },
                {
                  name: 'Adults',
                  value: 'adults',
                  enabled: true,
                },
                {
                  name: 'Kids',
                  value: 'kids',
                  enabled: true,
                },
                {
                  name: 'Weddings',
                  value: 'aweddings',
                  enabled: true,
                },
                {
                  name: 'Novelty',
                  value: 'novelty',
                  enabled: true,
                },
              ],
              flavours: [
                {
                  name: 'Select Flavour',
                  value: '',
                  enabled: false,
                },
                {
                  name: ' vanilla',
                  value: 'vanilla',
                  enabled: true,
                },
                {
                  name: 'strawberry',
                  value: 'strawberry',
                  enabled: true,
                },
                {
                  name: 'chocolate',
                  value: 'chocolate',
                  enabled: true,
                },
                {
                  name: 'red-velvet',
                  value: 'red-velvet',
                  enabled: true,
                },
                {
                  name: 'chocolate & strawberry',
                  value: 'chocolate & strawberry',
                  enabled: true,
                },
                {
                  name: 'chocolate & red-velvet',
                  value: 'chocolate & red-velvet',
                  enabled: true,
                },
                {
                  name: 'strawberry & red-velvet',
                  value: 'strawberry & red-velvet',
                  enabled: true,
                },
                {
                  name: 'vanilla & chocolate',
                  value: 'vanilla & chocolate',
                  enabled: true,
                },
                {
                  name: 'vanilla & strawberry',
                  value: 'vanilla & strawberry',
                  enabled: true,
                },
                
                {
                  name:'vanilla, red-velvet',
                  value:'vanilla, red-velvet',
                  enabled: true,
                },
                {
                  name:'vanilla, red-velvet & chocolate',
                  value:'vanilla, red-velvet & chocolate',
                  enabled: true,
                },
                {
                  name:'vanilla, strawberry & red-velvet',
                  value:'vanilla, strawberry & red-velvet',
                  enabled: true,
                },
              ],
              frosting: [
                {
                  name: 'Select Frosting',
                  value: '',
                  enabled: false,
                },
                {
                  name: ' whippedcream',
                  value: 'whippedcream',
                  enabled: true,
                },
                {
                  name: 'fondant',
                  value: 'fondant',
                  enabled: true,
                },
                {
                  name: 'butter-icing',
                  value: 'butter-icing',
                  enabled: true,
                },
              ],
              size: [
                {
                  name: 'Select Size',
                  value: '',
                  enabled: false,
                },

                {
                  name: '8x4',
                  value: '8x4',
                  enabled: true,
                },
                {
                  name: '8x6',
                  value: '8x6',
                  enabled: true,
                },
                {
                  name: '8x8',
                  value: '8x8',
                  enabled: true,
                },
                {
                  name: '10x6',
                  value: '10x6',
                  enabled: true,
                },
                {
                  name: '12x6',
                  value: '12x6',
                  enabled: true,
                },
              ],
            }
          )?.map(mapToFormSelect)}

          <FileUpload
            id="images"
            name="images"
            upload={upload}
            value={addCakeState.images}
            handler={(event) =>
              fileSizeHandler(
                event,
                onchangeHandler,
                setUpload,
                uploadState,
                3000000
              )
            }
          />
          {loading && <LoadingButton content="Submitting Cake..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCakes;
