import React from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(3),
    width: 220,
  },
}));

export default function DateTimeInput(props) {
  const classes = useStyles();
  const { minimumDate, className } = props;
  const selectedDate = props.value || props.minimumDate || new Date();

  const handleDateChange = (date) => {
    const allowedDate =
      minimumDate && moment(minimumDate).isAfter(selectedDate)
        ? minimumDate
        : date;
    props.handler(allowedDate);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker
        className={clsx(classes.input, className)}
        autoOk
        variant="inline"
        inputVariant={props.variant || 'outlined'}
        ampm={props.ampm || false}
        label={props.labelContent}
        value={selectedDate}
        onChange={handleDateChange}
        onError={console.log}
        minDate={minimumDate}
        format="DD-MM-YYYY HH:mm"
        InputAdornmentProps={{ position: 'start' }}
      />
    </MuiPickersUtilsProvider>
  );
}
