{/* eslint-disable-line react/no-array-index-key */}
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DateInput from './dateInput';
import DateTimeInput from './dateTimeInput';
import { StyledRadio, StyledCheckBox } from '../materialUI';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    border: '1px solid lightgray',
  },
  dateInput: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0),
  },
  checkBox: {
    margin: theme.spacing(0, 0, 0, 0),
    color: '#a2a2a8',
    padding: theme.spacing(1),
  },
  formControlLabel: {
    margin: theme.spacing(0, 1),
    width: 'fit-content',
    '& .MuiRadio-root': {
      padding: theme.spacing(1),
    },
    '& .MuiFormControlLabel-label': {
      color: '#a2a2a8',
      fontSize: '14px',
    },
  },
}));

const DateFilter = ({ state, setState, radio }) => {
  const classes = useStyles();

  const handleCheckBox = (event) => {
    const { checked } = event.target;
    setState((prevVal) => ({
      ...prevVal,
      isChecked: checked,
    }));
  };

  const handleFilterStartDate = (date) => {
    setState((prevVal) => ({
      ...prevVal,
      startDate: date,
    }));
  };

  const handleFilterEndDate = (date) => {
    setState((prevVal) => ({
      ...prevVal,
      endDate: date,
    }));
  };

  const handleColumn = (event) => {
    const { value } = event.target;
    setState((prevVal) => ({
      ...prevVal,
      startDate: null,
      endDate: null,
      column: value,
    }));
  };

  const { startDate, endDate, isChecked, column } = state;

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <FormControl component="fieldset">
          <RadioGroup
            className={classes.radioGroup}
            aria-label="choose-date"
            name="column"
            value={column}
            onChange={handleColumn}
          >
            {radio.map((entry, index) => (
              <FormControlLabel
                key={index}
                className={classes.formControlLabel}
                value={entry.value}
                control={<StyledRadio />}
                label={entry.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      {isChecked ? (
        <Grid container direction="row" justify="space-between" spacing={0}>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <DateTimeInput
              className={classes.dateInput}
              labelContent="Start Date & Time"
              handler={handleFilterStartDate}
              value={startDate || ''}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <DateTimeInput
              className={classes.dateInput}
              labelContent="End Date & Time"
              handler={handleFilterEndDate}
              value={endDate || ''}
              minimumDate={startDate ? startDate : undefined}
              variant="standard"
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" justify="space-between" spacing={0}>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <DateInput
              className={classes.dateInput}
              labelContent="Start Date"
              handler={handleFilterStartDate}
              value={startDate || ''}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <DateInput
              className={classes.dateInput}
              labelContent="End Date"
              handler={handleFilterEndDate}
              value={endDate || ''}
              minimumDate={startDate ? startDate : undefined}
              variant="standard"
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={0}>
        <FormControlLabel
          control={
            <StyledCheckBox
              name="toggleRange"
              className={classes.checkBox}
              checked={isChecked || false}
              onChange={handleCheckBox}
            />
          }
          label="Filter with time"
          className={classes.formControlLabel}
        />
      </Grid>
    </div>
  );
};

export default DateFilter;
