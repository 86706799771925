import swal from 'sweetalert';

export default function confirmAlert(text, action) {
  return swal({
    title: 'Confirm!',
    text: text || 'Do you wish to proceed',
    icon: 'warning',
    buttons: ['Cancel', 'Proceed'],
  }).then((willProceed) => {
    if (willProceed) {
      action && action();
    }
  });
}
