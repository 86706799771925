import React from 'react';
import { Orders } from '../order';

const Dashboard = () => {
  return (
    <div className="animated fadeIn">
      {/* <!-- Widgets  --> */}
      {/* <!-- /Widgets -->
                    <!--  Traffic  --> */}
     {/* <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="box-title">Traffic </h4>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="card-body">
                  <div id="traffic-chart" className="traffic-chart"></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-body">
                  <div className="progress-box progress-1">
                    <h4 className="por-title">Visits</h4>
                    <div className="por-txt">96,930 Users (40%)</div>
                    <div className="progress mb-2" style={{ height: 5 }}>
                      <div
                        className="progress-bar bg-flat-color-1"
                        role="progressbar"
                        style={{
                          width: '40%',
                          ariaValuenow: 25,
                          ariaValuemin: 0,
                          ariaValuemax: 100,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="progress-box progress-2">
                    <h4 className="por-title">Bounce Rate</h4>
                    <div className="por-txt">3,220 Users (24%)</div>
                    <div className="progress mb-2" style={{ height: 5 }}>
                      <div
                        className="progress-bar bg-flat-color-2"
                        role="progressbar"
                        style={{
                          width: '24%',
                          ariaValuenow: 25,
                          ariaValuemin: 0,
                          ariaValuemax: 100,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="progress-box progress-2">
                    <h4 className="por-title">Unique Visitors</h4>
                    <div className="por-txt">29,658 Users (60%)</div>
                    <div className="progress mb-2" style={{ height: 5 }}>
                      <div
                        className="progress-bar bg-flat-color-3"
                        role="progressbar"
                        style={{
                          width: '60%',
                          ariaValuenow: 60,
                          ariaValuemin: 0,
                          ariaValuemax: 100,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="progress-box progress-2">
                    <h4 className="por-title">Targeted Visitors</h4>
                    <div className="por-txt">99,658 Users (90%)</div>
                    <div className="progress mb-2" style={{ height: 5 }}>
                      <div
                        className="progress-bar bg-flat-color-4"
                        role="progressbar"
                        style={{
                          width: '90%',
                          ariaValuenow: 90,
                          ariaValuemin: 0,
                          ariaValuemax: 100,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body"></div>
          </div>
        </div>
                      </div> */}
      {/* <!--  /Traffic --> */}
      <div className="clearfix"></div>
      {/* <!-- Orders --> */}
      <Orders />
        
      {/* <!-- /.orders -->
                    <!-- To Do and Live Chat --> */}
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title box-title">To Do List</h4>
              <div className="card-content">
                <div className="todo-list">
                  <div className="tdl-holder">
                    <div className="tdl-content">
                      <ul>
                        <li>
                          <label>
                            <input type="checkbox" />
                            <i className="check-box"></i>
                            <span>
                              Conveniently fabricate interactive technology for
                              ....
                            </span>
                            <a href="#" className="fa fa-times"></a>
                            <a href="#" className="fa fa-pencil"></a>
                            <a href="#" className="fa fa-check"></a>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input type="checkbox" />
                            <i className="check-box"></i>
                            <span>Creating component page</span>
                            <a href="#" className="fa fa-times"></a>
                            <a href="#" className="fa fa-pencil"></a>
                            <a href="#" className="fa fa-check"></a>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input type="checkbox" checked />
                            <i className="check-box"></i>
                            <span>Follow back those who follow you</span>
                            <a href="#" className="fa fa-times"></a>
                            <a href="#" className="fa fa-pencil"></a>
                            <a href="#" className="fa fa-check"></a>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input type="checkbox" checked />
                            <i className="check-box"></i>
                            <span>Design One page theme</span>
                            <a href="#" className="fa fa-times"></a>
                            <a href="#" className="fa fa-pencil"></a>
                            <a href="#" className="fa fa-check"></a>
                          </label>
                        </li>

                        <li>
                          <label>
                            <input type="checkbox" checked />
                            <i className="check-box"></i>
                            <span>Creating component page</span>
                            <a href="#" className="fa fa-times"></a>
                            <a href="#" className="fa fa-pencil"></a>
                            <a href="#" className="fa fa-check"></a>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- /.todo-list --> */}
              </div>
            </div>
            {/* <!-- /.card-body --> */}
          </div>
          {/* <!-- /.card --> */}
        </div>

        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title box-title">Live Chat</h4>
              <div className="card-content">
                <div className="messenger-box">
                  <ul>
                    <li>
                      <div className="msg-received msg-container">
                        <div className="avatar">
                          <img src="images/avatar/64-1.jpg" alt="" />
                          <div className="send-time">11.11 am</div>
                        </div>
                        <div className="msg-box">
                          <div className="inner-box">
                            <div className="name">John Doe</div>
                            <div className="meg">
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Perspiciatis sunt placeat velit
                              ad reiciendis ipsam
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- /.msg-received --> */}
                    </li>
                    <li>
                      <div className="msg-sent msg-container">
                        <div className="avatar">
                          <img src="images/avatar/64-2.jpg" alt="" />
                          <div className="send-time">11.11 am</div>
                        </div>
                        <div className="msg-box">
                          <div className="inner-box">
                            <div className="name">John Doe</div>
                            <div className="meg">Hay how are you doing?</div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- /.msg-sent --> */}
                    </li>
                  </ul>
                  <div className="send-mgs">
                    <div className="yourmsg">
                      <input className="form-control" type="text" />
                    </div>
                    <button className="btn msg-send-btn">
                      <i className="pe-7s-paper-plane"></i>
                    </button>
                  </div>
                </div>
                {/* <!-- /.messenger-box --> */}
              </div>
            </div>
            {/* <!-- /.card-body --> */}
          </div>
          {/* <!-- /.card --> */}
        </div>
      </div>
      {/* <!-- /To Do and Live Chat -->
                    <!-- Calender Chart Weather  --> */}
      <div className="row">
        <div className="col-md-12 col-lg-4">
          <div className="card">
            <div className="card-body">
              {/* <!-- <h4 className="box-title">Chandler</h4> --> */}
              <div className="calender-cont widget-calender">
                <div id="calendar"></div>
              </div>
            </div>
          </div>
          {/* <!-- /.card --> */}
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="card ov-h">
            <div className="card-body bg-flat-color-2">
              <div id="flotBarChart" className="float-chart ml-4 mr-4"></div>
            </div>
            <div id="cellPaiChart" className="float-chart"></div>
          </div>
          {/* <!-- /.card --> */}
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="card weather-box">
            <h4 className="weather-title box-title">Weather</h4>
            <div className="card-body">
              <div className="weather-widget">
                <div id="weather-one" className="weather-one"></div>
              </div>
            </div>
          </div>
          {/* <!-- /.card --> */}
        </div>
      </div>
      {/* <!-- /Calender Chart Weather -->
                    <!-- Modal - Calendar - Add New Event --> */}
      <div className="modal fade none-border" id="event-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4 className="modal-title">
                <strong>Add New Event</strong>
              </h4>
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success save-event waves-effect waves-light"
              >
                Create event
              </button>
              <button
                type="button"
                className="btn btn-danger delete-event waves-effect waves-light"
                data-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /#event-modal -->
                    <!-- Modal - Calendar - Add Category --> */}
      <div className="modal fade none-border" id="add-category">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4 className="modal-title">
                <strong>Add a category </strong>
              </h4>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <label className="control-label">Category Name</label>
                    <input
                      className="form-control form-white"
                      placeholder="Enter name"
                      type="text"
                      name="category-name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="control-label">
                      Choose Category Color
                    </label>
                    <select
                      className="form-control form-white"
                      data-placeholder="Choose a color..."
                      name="category-color"
                    >
                      <option value="success">Success</option>
                      <option value="danger">Danger</option>
                      <option value="info">Info</option>
                      <option value="pink">Pink</option>
                      <option value="primary">Primary</option>
                      <option value="warning">Warning</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light save-category"
                data-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /#add-category --> */}
    </div>
  );
};

export default Dashboard;
