{/* eslint-disable-line react/no-array-index-key */}
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import { CustomCard } from '../../components/cards';
import { MuiModal } from '../../components/modals';
import {
  inputHandler,
  updateItemState,
  createInputData,
  fileSizeHandler,
} from '../../utility';
import {
  fetchResources,
  updateResource,
  deleteResource,
} from '../../redux/actions/userActions';
import { mapToFormInput, FileUpload } from '../../components/form-components';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
  },
  cardPane: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  cardGrid: {
    padding: theme.spacing(0),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  headerText: {
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  headerButton: {
    border: 'none',
    backgroundColor: '#fff',
    color: 'gray',
    '&:hover': {
      color: '#55c2d9',
    },
  },
  headerButtonIcon: {
    width: '20px !important',
    height: '20px !important',
  },
  footer: {
    width: '100%',
  },
}));

const ViewResources = () => {
  const classes = useStyles();
  const {
    profile: { id },
    resources,
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const initialState = {
    title: '',
    specification: '',
    owner: '',
    images: [],
    _id: '',
  };

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };

  const [resourceState, setResourceState] = useState(initialState);
  const [updateResourceModal, setUpdateResourceModal] = useState(false);
  const [page, setPage] = useState(0);
  const [upload, setUpload] = useState(uploadState);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
   // console.log('cake', result);
  useEffect(() => {
    dispatch(fetchResources(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteResource = (data) => {
    dispatch(deleteResource(data));
  };

  const onchangeHandler = (e) => {
    inputHandler(e, setResourceState);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // const data = { ...resourceState, ...{ updatedBy: id } };
    const formData = new FormData();

    const { title, specification, images, owner } = resourceState;

    images.length &&
      images.forEach((image) => {
        formData.append('images', image);
      });
    title && formData.append('title', title);
    specification && formData.append('specification', specification);
    formData.append('owner', owner._id);
    formData.append('updatedBy', id);
    dispatch(updateResource(formData, resourceState['_id'], owner._id));
  };

  const toggleUpdateResourceModal = () => {
    setUpdateResourceModal((prevVal) => !prevVal);
  };

  const updateResourceState = (data) => {
    updateItemState(
      data,
      setResourceState,
      toggleUpdateResourceModal,
      initialState
    );
  };

  // Render functions

  const renderUpdateResource = () => {
    return (
      <div>
        {createInputData(
          {
            title: resourceState.title,
          },
          onchangeHandler
        )?.map(mapToFormInput)}
        <textarea
          style={{
            margin: '25px 30px',
            display: 'block',
            width: '100%',
            // minWidth: 500,
            maxWidth: 500,
            minHeight: 300,
            maxHeight: 500,
          }}
          name="specification"
          value={resourceState.specification}
          onChange={onchangeHandler}
          placeholder="specification..."
        />

        <FileUpload
          id="images"
          name="images"
          upload={upload}
          value={resourceState.images}
          handler={(event) =>
            fileSizeHandler(
              event,
              onchangeHandler,
              setUpload,
              uploadState,
              3000000
            )
          }
        />
        {createInputData(
          {
            owner: resourceState.owner,
            createdBy: resourceState.createdBy,
            updatedBy: resourceState.updatedBy,
          },
          onchangeHandler
        )?.map(mapToFormInput)}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <div className={classes.header}>
          <Typography
            gutterBottom
            className={clsx('top-radius', classes.headerText)}
          >
            Resources
          </Typography>
        </div>
        <Container className={classes.cardPane} maxWidth="lg">
          <Grid container className={classes.cardGrid} spacing={2}>
            {loading ? (
              <div>Loading...</div>
            ) : (
              resources &&
              resources
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((resource, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      item="resource"
                      items={[
                        {
                          modal: true,
                          name: 'Edit',
                          action: () => updateResourceState(resource),
                        },
                        {
                          modal: true,
                          name: 'Delete',
                          action: () =>
                            handleDeleteResource({ _id: resource._id }),
                        },
                      ]}
                      images={resource.images}
                      deleteUrl={`api/v1/dashboard/resource/${resource['_id']}/image/delete`}
                      additionalData={{ owner: resource.owner['_id'] }}
                      content={[
                        resource.title,
                        resource.specification.trim().replaceAll('\n', ', '),
                      ]}
                    />
                  </Grid>
                ))
            )}
          </Grid>
        </Container>
        <TablePagination
          className={classes.footer}
          labelRowsPerPage="Resource per page"
          rowsPerPageOptions={[4, 8, 16]}
          component="div"
          count={resources?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <MuiModal
        open={updateResourceModal}
        handleClose={toggleUpdateResourceModal}
        title="Update Resource"
        content={renderUpdateResource}
        handler={submitHandler}
      />
    </div>
  );
};

export default ViewResources;
