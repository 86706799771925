import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { uploadVendor } from '../../redux/actions/userActions';
import {
  mapToFormInput,
  FormButton,
  LoadingButton,
} from '../../components/form-components';
import { createInputData, inputHandler } from '../../utility';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

const AddVendors = () => {
  const classes = useStyles();
  const {
    profile: { id },
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    firstName: '',
    lastName: '',
    address: '',
    phone: '',
    bank: '',
    accountNumber: '',
    accountName: '',
    businessName: '',
    city: '',
    state: '',
  };
  const [addVendorState, setAddVendorState] = useState(initialState);

  const onchangeHandler = (e) => {
    inputHandler(e, setAddVendorState);
  };

  const resetHandler = () => {
    setAddVendorState(initialState);
    document.getElementById('myForm').reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const { firstName, address, phone } = addVendorState;
    if (firstName && address && phone) {
      const data = { ...addVendorState, ...{ createdBy: id } };
      dispatch(uploadVendor(data));
      resetHandler();
    } else {
      swal({
        title: 'Hey!',
        text: 'complete all fields',
        icon: 'warning',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New Vendor</p>
          {createInputData(addVendorState, onchangeHandler)?.map(
            mapToFormInput
          )}
          {loading && <LoadingButton content="Submitting Vendor..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVendors;
