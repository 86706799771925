import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { uploadReview } from '../../redux/actions/userActions';
import {
  mapToFormInput,
  mapToFormSelect,
  FormButton,
  FileUpload,
  LoadingButton,
} from '../../components/form-components';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { inputHandler, createInputData, fileSizeHandler } from '../../utility';
import { DateInput } from '../../components/form-components';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

const AddReviews = () => {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.process);
  const dispatch = useDispatch();
  const initialState = {
    title: '',
    size: '',
    comment: '',
    name: '',
    date: '',
    rating: 3,
    images: [],
  };

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };

  const [addReviewState, setAddReviewState] = useState(initialState);
  const [upload, setUpload] = useState(uploadState);

  const onchangeHandler = (e) => {
    inputHandler(e, setAddReviewState);
  };

  const resetHandler = () => {
    setAddReviewState(initialState);
    setUpload(uploadState);
    document.getElementById('myForm').reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const { images, title, comment, name, rating, date } = addReviewState;
    images.forEach((image) => {
      formData.append('images', image);
    });
    formData.append('title', title);
    formData.append('name', name);
    formData.append('comment', comment);
    formData.append('rating', rating);
    formData.append('date', date);

    if (images.length && date) {
      dispatch(uploadReview(formData));
      resetHandler();
    } else {
      swal({
        title: 'Hey!',
        text: 'complete all fields',
        icon: 'warning',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New Review</p>
          {createInputData(
            { title: addReviewState.title, name: addReviewState.name },
            onchangeHandler
          )?.map(mapToFormInput)}

          <textarea
            style={{
              margin: '25px 30px',
              display: 'block',
              width: '100%',
              // minWidth: 500,
              maxWidth: 500,
              minHeight: 300,
              maxHeight: 500,
            }}
            name="comment"
            value={addReviewState.comment}
            onChange={onchangeHandler}
            placeholder="comment..."
          />

          <DateInput
            labelContent="Delivery Date"
            value={addReviewState.date}
            // minimumDate={new Date(
            //   new Date().setHours(new Date().getHours() + 12)
            // )
            //   .toISOString()
            //   .slice(0, 10)}
            handler={(date) => {
              setAddReviewState((prevVal) => {
                return { ...prevVal, date };
              });
            }}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 4,
              maxWidth: 400,
            }}
          >
            <Typography component="legend">Rating</Typography>
            <Rating
              name="simple-controlled"
              value={addReviewState.rating}
              onChange={(event, newValue) => {
                setAddReviewState((prevState) => ({
                  ...prevState,
                  rating: newValue,
                }));
              }}
            />
          </Box>

          {createInputData(
            {
              size: addReviewState.size,
            },
            onchangeHandler,
            {
              size: [
                {
                  name: 'Select Size',
                  value: '',
                  enabled: true,
                },
                {
                  name: 'Small',
                  value: 'small',
                  enabled: true,
                },
                {
                  name: 'Big',
                  value: 'big',
                  enabled: true,
                },
              ],
            }
          )?.map(mapToFormSelect)}

          <FileUpload
            id="images"
            name="images"
            upload={upload}
            value={addReviewState.images}
            handler={(event) =>
              fileSizeHandler(
                event,
                onchangeHandler,
                setUpload,
                uploadState,
                3000000
              )
            }
          />
          {loading && <LoadingButton content="Submitting Review..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddReviews;
