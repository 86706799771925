import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0),
    width: '100%',
    background: 'white',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 4),
      justifyContent: 'flex-end',
    },
  },
  footerText: {
    margin: theme.spacing(0),
  },
}));

const getDate = new Date();
const year = getDate.getFullYear();
const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={clsx('shadow', 'mui-appbar', classes.footer)}>
      <p className={classes.footerText}>Copyright &copy; {year} Yefepere</p>
    </footer>
  );
};

export default Footer;
