import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { uploadGift } from '../../redux/actions/userActions';
import {
  mapToFormInput,
  mapToFormSelect,
  FormButton,
  FileUpload,
  LoadingButton,
} from '../../components/form-components';
import { createInputData, inputHandler, fileSizeHandler } from '../../utility';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

const AddGifts = () => {
  const classes = useStyles();
  const {
    profile: { id },
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    title: '',
    description: '',
    specification: {
      size: '',
      quantity: '',
      weight: '',
    },
    price: '',
    category: '',
    size: '',
    images: [],
  };

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };

  const [addGiftState, setAddGiftState] = useState(initialState);
  const [upload, setUpload] = useState(uploadState);

  const onchangeHandler = (e) => {
    const { name, value } = e.target;

    if (name.includes('spec')) {
      const realName = name.slice(4).toLowerCase();
      return setAddGiftState((prevVal) => ({
        ...prevVal,
        specification: {
          ...prevVal.specification,
          [realName]: value,
        },
      }));
    }
    return inputHandler(e, setAddGiftState);
  };

  const resetHandler = () => {
    setAddGiftState(initialState);
    setUpload(uploadState);
    document.getElementById('myForm').reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const {
      images,
      title,
      description,
      price,
      category,
      size,
      specification,
    } = addGiftState;

    images.forEach((image) => {
      formData.append('images', image);
    });
    formData.append('title', title);
    formData.append('description', description);
    formData.append('specification', JSON.stringify(specification));
    formData.append('price', price);
    formData.append('category', category);
    formData.append('size', size);
    formData.append('createdBy', id);

    if (title && price && images.length) {
      dispatch(uploadGift(formData));
      resetHandler();
    } else {
      swal({
        title: 'Hey!',
        text: 'complete all fields',
        icon: 'warning',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New Gift</p>
          {createInputData(
            {
              title: addGiftState.title,
              description: addGiftState.description,
              price: addGiftState.price,
              specQuantity: addGiftState.specification?.quantity,
              specSize: addGiftState.specification?.size,
              specWeight: addGiftState.specification?.weight,
            },
            onchangeHandler
          )?.map(mapToFormInput)}
          {createInputData(
            {
              category: addGiftState.category,
              size: addGiftState.size,
            },
            onchangeHandler,
            {
              category: [
                {
                  name: 'Select Category',
                  value: '',
                  enabled: false,
                },
                {
                  name: 'Electronics',
                  value: 'electronics',
                  enabled: true,
                },
                {
                  name: 'Cosmetics',
                  value: 'cosmetics',
                  enabled: true,
                },
                {
                  name: 'Fashion',
                  value: 'fashion',
                  enabled: true,
                },
              ],
              size: [
                {
                  name: 'Select Size',
                  value: '',
                  enabled: true,
                },
                {
                  name: 'Small',
                  value: 'small',
                  enabled: true,
                },
                {
                  name: 'Big',
                  value: 'big',
                  enabled: true,
                },
              ],
            }
          )?.map(mapToFormSelect)}

          <FileUpload
            id="images"
            name="images"
            upload={upload}
            value={addGiftState.images}
            handler={(event) =>
              fileSizeHandler(
                event,
                onchangeHandler,
                setUpload,
                uploadState,
                3000000
              )
            }
          />
          {loading && <LoadingButton content="Submitting Gift..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddGifts;
