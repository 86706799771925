import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { uploadItem } from '../../redux/actions/userActions';
import {
  mapToFormInput,
  FormButton,
  FileUpload,
  LoadingButton,
} from '../../components/form-components';
import { createInputData, inputHandler, fileSizeHandler } from '../../utility';
import '../../app-assets/styles/css/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: theme.spacing(3, 3, 6),
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  subheader: {
    margin: theme.spacing(3),
    fontWeight: '800',
    color: '#55c2d9',
  },
  formFooter: {
    padding: theme.spacing(3),
  },
}));

const AddMarketItems = () => {
  const classes = useStyles();
  const {
    profile: { id },
    process: { loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const initialState = {
    title: '',
    description: '',
    price: '',
    category: '',
    size: '',
    details: '',
    deliveryOptions: '',
    paymentOptions: '',
    metaTag: '',
    logistics: '',
    images: [],
  };

  const uploadState = {
    error: false,
    message: 'Maximum of 3mb per file',
  };

  const [addItemState, setAddItemState] = useState(initialState);
  const [upload, setUpload] = useState(uploadState);

  const onchangeHandler = (e) => {
    inputHandler(e, setAddItemState);
  };

  const resetHandler = () => {
    setAddItemState(initialState);
    setUpload(uploadState);
    document.getElementById('myForm').reset();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const {
      images,
      title,
      description,
      price,
      category,
      size,
      details,
      metaTag,
      deliveryOptions,
      paymentOptions,
      logistics,
    } = addItemState;
    images.forEach((image) => {
      formData.append('images', image);
    });
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('category', category);
    formData.append('size', size);
    formData.append('details', details);
    formData.append('logistics', logistics);
    formData.append('deliveryOptions', deliveryOptions);
    formData.append('paymentOptions', paymentOptions);
    formData.append('metaTag', metaTag);
    formData.append('createdBy', id);

    if (title && price && images.length) {
      dispatch(uploadItem(formData));
      resetHandler();
    } else {
      swal({
        title: 'Hey!',
        text: 'complete all fields',
        icon: 'warning',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <form
          action="#"
          id="myForm"
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <p className={classes.header}>New Item</p>
          {createInputData(
            {
              title: addItemState.title,
              description: addItemState.description,
              metaTag: addItemState.metaTag,
              details: addItemState.details,
              deliveryOptions: addItemState.deliveryOptions,
              paymentOptions: addItemState.paymentOptions,
              logistics: addItemState.logistics,
              price: addItemState.price,
            },
            onchangeHandler
          )?.map(mapToFormInput)}

          <FileUpload
            id="images"
            name="images"
            upload={upload}
            value={addItemState.images}
            handler={(event) =>
              fileSizeHandler(
                event,
                onchangeHandler,
                setUpload,
                uploadState,
                3000000
              )
            }
          />
          {loading && <LoadingButton content="Submitting Item..." />}
          <div className={classes.formFooter}>
            <FormButton content="Submit" handler={submitHandler} />
            <FormButton content="Reset" handler={resetHandler} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMarketItems;
